import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../_metronic/_partials/controls";
  import {
      Table,
      Pagination,
      Modal,
      Button,
      Form,
      Tab,
      Tabs,
      Alert
  } from 'react-bootstrap'
  

import DatePicker from 'react-datepicker'
//import { Editor } from '@tinymce/tinymce-react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from 'ckeditor4-react';

import ImageUploader from "react-images-upload"
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import {allCategoriesParentChild,allbrands,allProductsPager,productListCount,addNewProduct,editProduct,productAtrributes,deleteProductAttribute,singleImage,addProductImages,DeleteProductImages,deleteProduct,BasicConfigrations} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
//Form Builder
import TextComponent from './Components/TextComponent'
import { object } from "prop-types";
//   import BootstrapTable from 'react-bootstrap-table-next';
import { isAuthenticated } from "../API/MarchantAuth";

export const Products = ({props}) => {

    const [configrations,set_configrations] = useState({})

    const loadConfigrations = ()=>{
        BasicConfigrations().then((data)=>{
          set_configrations(data)
        })
    }

    const [categories,getCategories] = useState([])
    const [brands,getBrands]         = useState([])

    const [products,getProducts] = useState([])
    const [productsCount,getProductsCount] = useState(0)
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    
    const loadCategories = ()=>{
        allCategoriesParentChild().then((data)=>{
            getCategories(data)
        })
    }
    const loadBrands = ()=>{
        allbrands().then((data)=>{
            getBrands(data)
        })
    }

    const loadProducts = ()=>{
        allProductsPager(skip,limit).then((data)=>{
            getProducts(data)
        })
    }
    const loadProductCount = ()=>{
        productListCount().then((data)=>{
            getProductsCount(data.length)

            var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
            console.log("Lenght" +pagesLength)
            var pagesArray  = []
            for(var i=0;i<pagesLength;i++){
                pagesArray.push(i+1);
            }
            setCurrentPage(1)
            setPages(pagesArray)
        })
    }
    
    useEffect(()=>{
        loadCategories()
        loadBrands()
        loadProducts()
        loadProductCount()
        loadConfigrations()
    },[])

    const nextPage = (pageNumber)=>{ //Pager
        allProductsPager((pageNumber-1)*10,limit).then((data)=>{
            getProducts(data)
            setCurrentPage(pageNumber)
        })
    }
    // Add Product
    const [lgShow,setlgShow] = useState(false)
    const addNewProductModel = ()=>{ //Add Model Display
        setlgShow(true)
    }
    //product category
    const [ProductCategory,setProductCategory] = useState()
    //product product_title
    const [ProductTitle,setProductTitle]       = useState()
    //product product_price
    const [ProductPrice,setProductPrice]       = useState()
    //product product_quantity
    const [ProductQuantity,setProductQuantity] = useState()
    //product product_type
    const [ProductType,setProductType]         = useState()
    //Product brand
    const [ProductBrand,setProductBrand]       = useState()
    // Product product_require_shipping
    const [ProductShipping,setProductShipping] = useState()
    // Product product_sub_title
    const [ProductSubTitle,setProductSubTitle] = useState()
    // Product product_promo_title
    const [ProductPromoTitle,setProductPromoTitle] = useState()
    // Product product_cost
    const [ProductCost,setProductCost] = useState()
    // Product product_discount_price
    const [ProductDiscountPrice,setProductDiscountPrice] = useState()
    // Product product_discount_price_enddate
    const [ProductDiscountPriceEnd,setProductDiscountPriceEnd] = useState()
    // Product product_weight
    const [ProductWieght,setProductWieght] = useState()
    // Product product_order_min_quantity
    const [ProductQuantityMin,setProductQuantityMin] = useState()
    // Product product_order_max_quantity
    const [ProductQuantityMax,setProductQuantityMax] = useState()
    // Product product_short_description
    const [ProductShortDescription,setProductShortDescription] = useState()
    // Product product_long_description
    const [ProductDescription,setProductDescription] = useState()
    // Product product_seo_title
    const [ProductSeoTitle,setProductSeoTitle] = useState()    
    // Product product_seo_description
    const [ProductSeoDescription,setProductSeoDescription] = useState()

    //Product Images
    const [pictures,setPictures] = useState()
    const onDrop = picture => {
        setPictures(picture);
    };
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            
            addNewProduct({
                category:ProductCategory,
                product_title:ProductTitle,
                product_price:ProductPrice,
                product_quantity:ProductQuantity,
                product_type:ProductType,
                // product_image:pictures
            }).then((data)=>{
                loadProducts()
                loadProductCount()
                setlgShow(false)
            })
        }
    }
    //selected Product
    const [selectedProduct,setSelectedProduct] = useState({})
    const setCurrentSelectedProduct = (product,model)=>{
        setSelectedProduct(product)
        
        setProductCategory(product.category)
        setProductTitle(product.product_title)
        setProductPrice(product.product_price)
        setProductQuantity(product.product_quantity)
        setProductType(product.product_type)
        setProductBrand(product.brand)
        setProductShipping(product.product_require_shipping)
        setProductSubTitle(product.product_sub_title)
        setProductPromoTitle(product.product_promo_title)
        setProductCost(product.product_cost)
        setProductDiscountPrice(product.product_discount_price)
        setProductDiscountPriceEnd(product.product_discount_price_enddate)
        setProductWieght(product.product_weight)
        setProductQuantityMin(product.product_order_min_quantity)
        setProductQuantityMax(product.product_order_max_quantity)
        setProductShortDescription(product.product_short_description)
        setProductDescription(product.product_long_description)
        setProductSeoTitle(product.product_seo_title)
        setProductSeoDescription(product.product_seo_description)

        
        const attributearray = []
        if(product.productAttributes.length){
            for(var i=0;i<product.productAttributes.length;i++){
                attributearray[i] = {
                    id:product.productAttributes[i]._id,
                    text:product.productAttributes[i].attribute_name,
                    type:product.productAttributes[i].attribute_type,
                    options:[]
                }
                if(product.productAttributes[i].attributeValues.length){
                    for(var x=0;x<product.productAttributes[i].attributeValues.length;x++){
                        console.log(i)
                        attributearray[i].options[x] = {
                            id:product.productAttributes[i].attributeValues[x]._id,
                            v1:product.productAttributes[i].attributeValues[x].attribute_value_name,
                            v2:product.productAttributes[i].attributeValues[x].attribute_value_extra_price,
                            v3:product.productAttributes[i].attributeValues[x].attribute_value_quantity
                        }
                    }

                }
            }
        }
        setInputGrups(attributearray)
        if(model === "edit")
            setlgEditShow(true)
        else if(model === 'delete')
            setlgShowDelete(true)    
        
        setFormMessage([])    
    }

    const handleSubmitFirst = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            editProduct({
                _id:selectedProduct._id,
                category:ProductCategory,
                product_title:ProductTitle,
                product_price:ProductPrice,
                product_quantity:ProductQuantity,
                product_type:ProductType,
                brand:ProductBrand,
                product_require_shipping:ProductShipping,
                product_sub_title:ProductSubTitle,
                product_promo_title:ProductPromoTitle,
                product_cost:ProductCost,
                product_discount_price:ProductDiscountPrice,
                product_discount_price_enddate:ProductDiscountPriceEnd,
                product_weight:ProductWieght,
                product_order_min_quantity:ProductQuantityMin,
                product_order_max_quantity:ProductQuantityMax,
                product_short_description:ProductShortDescription,
                product_long_description:ProductDescription,
                product_seo_title:ProductSeoTitle,
                product_seo_description:ProductSeoDescription
            }).then((data)=>{
                loadProducts()
                loadProductCount()
                setlgEditShow(false)
            })
        }
    } 
    const [FormMessage,setFormMessage] = useState([])

    
    const [InputGroups,setInputGrups] = useState([])
    const addNewItem = ()=>{
        const newArray = Object.assign([],InputGroups)
        newArray.push({
            id:InputGroups.length+1,
            text:"",
            type:"Text",
            options:[{
                id:1,
                v1:"",
                v2:"",
                v3:""
            },
            {
                id:2,
                v1:"",
                v2:"",
                v3:""
            },
            {
                id:3,
                v1:"",
                v2:"",
                v3:""
            }]
        })
        setInputGrups(newArray)
    }
    const addFieldToGroup = (inputId)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === inputId){
                newArray[i].options.push({
                    id:newArray[i].options.length,
                    v1:"",
                    v2:"",
                    v3:""
                })
            }
        }
        setInputGrups(newArray)
    }
    const inputGroupClick = (inputId)=>{ //Remove Option
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === inputId){
                newArray.splice(i,1)
            }
        }
        deleteProductAttribute(selectedProduct._id,inputId).then((data)=>{
            loadProducts()
            loadProductCount()
        })
        setInputGrups(newArray)
    }
    const changeOptionTitle = (e,inputId)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === inputId){
                newArray[i].text = e.target.value
                
            }
        }
        setInputGrups(newArray)
    }
    const changeOptionType = (e,inputId)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === inputId){
                newArray[i].type = e.target.value
                for(var x=0;x<newArray[i].options.length;x++){
                    newArray[i].options[x].v1 = ""
                    newArray[i].options[x].v2 = ""
                    newArray[i].options[x].v3 = ""
                }
            }
        }
        setInputGrups(newArray)
    }
    const changeV1Option = (e,inputIndex,componentId)=>{ //update field data
        
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === componentId){
                for(var x=0;x<newArray[i].options.length;x++){
                    if(inputIndex === x){
                        newArray[i].options[x].v1 = e.target.value
                    }
                }
            }
        }
        setInputGrups(newArray)
    
    
    }
    const changeV1OptionColor = (e,inputIndex,componentId)=>{ //update field data
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === componentId){
                for(var x=0;x<newArray[i].options.length;x++){
                    if(inputIndex === x){
                        newArray[i].options[x].v1 = e.hex
                    }
                }
            }
        }
        setInputGrups(newArray)
    }
    
    const changeV1OptionPicture = (e,inputIndex,componentId)=>{ //update field data
        singleImage(e[0]).then((data)=>{
            console.log(data)
            const newArray = Object.assign([],InputGroups)
            for(var i=0;i<newArray.length;i++){
                if(newArray[i].id === componentId){
                    for(var x=0;x<newArray[i].options.length;x++){
                        if(inputIndex === x){
                            newArray[i].options[x].v1 = data.image
                        }
                    }
                }
            }
            setInputGrups(newArray)
        })
        
    }

    const changeV2Option = (e,inputIndex,componentId)=>{ //update field data
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === componentId){
                for(var x=0;x<newArray[i].options.length;x++){
                    if(inputIndex === x){
                        newArray[i].options[x].v2 = e.target.value
                    }
                }
            }
        }
        setInputGrups(newArray)
    }
    const changeV3Option = (e,inputIndex,componentId)=>{ //update field data
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === componentId){
                for(var x=0;x<newArray[i].options.length;x++){
                    if(inputIndex === x){
                        newArray[i].options[x].v3 = e.target.value
                    }
                }
            }
        }
        setInputGrups(newArray)
    }

    const handleSubmitTwo = async ()=>{
        //InputGroups
        //productAtrributes(selectedProduct._id,InputGroups)
        var savingArray = []
        var tempArray = []
        for(var i=0;i<InputGroups.length;i++){
            if(InputGroups[i].text !== ""){
                tempArray.push({
                    _id:InputGroups[i].id,
                    attribute_description:"Description",
                    attribute_name:InputGroups[i].text,
                    attribute_type:InputGroups[i].type,
                    attribute_values:[]
                })
                for(var x=0;x<InputGroups[i].options.length;x++){
                    if(InputGroups[i].options[x].v1 && InputGroups[i].options[x].v2 && InputGroups[i].options[x].v3){
                        //tempArray.options.push(InputGroups[i].options[x])
                        tempArray[0].attribute_values.push({
                            "_id":InputGroups[i].options[x].id,
                            "attribute_value_name":InputGroups[i].options[x].v1,
                            "attribute_value_extra_price":InputGroups[i].options[x].v2,
                            "attribute_value_quantity":InputGroups[i].options[x].v3,
                        })
                    }
                }
                if(tempArray[0].attribute_values.length > 0){
                    savingArray.push({
                        _id:tempArray[0]._id,
                        attribute_description:"Description",
                        attribute_name:tempArray[0].attribute_name,
                        attribute_type:tempArray[0].attribute_type,
                        attribute_values:tempArray[0].attribute_values
                    })
                }
                tempArray = []
            }
        }
        if(savingArray.length > 0){
            //Save Data attributes
            productAtrributes(selectedProduct._id,{"attributes":savingArray}).then((data)=>{
                loadProducts()
                loadProductCount()
                setlgEditShow(false)
            })
        }
    }

    const handleSubmitThree = async (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if(pictures.length > 0){
            //console.log(pictures.length) //addProductImages
            await pictures.forEach((image,index) => {
                addProductImages(selectedProduct._id,image).then((data)=>{
                    
                    setFormMessage(
                        [...FormMessage,<Alert key={"alert"-index} variant={'success'}  dismissible> تم رفع الصورة {data.product_image}  </Alert>]
                    )
                    
                    setSelectedProduct({
                        ...selectedProduct,
                        productImages:[...selectedProduct.productImages,data]
                    })
                    //<Alert key={57} variant={'success'} onClose={handleDismiss} dismissible> تم اضافة القسم بنجاح </Alert>
                    loadProducts()
                    //loadProductCount()
                    //setlgEditShow(false)
                    setPictures([])
                })
                
            });
            setFormMessage([])
        }
    }

    //Edit 
    const [lgEditShow,setlgEditShow] = useState(false)
    const [selectedTabKey,setselectedTabKey] = useState('productedit')


    //const [startDate,setStartDate] = useState()

    //Delete
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const removeProduct = ()=>{
        deleteProduct(selectedProduct).then((data)=>{
            loadProducts()
            loadProductCount()
            setlgShowDelete(false)
            setSelectedProduct({})
        })
    }
    //Remove Product Image
    const RemoveProductImage = (imageId)=>{
        //
        const ImageArray = Object.assign({},selectedProduct)
        for(var i=0;i<ImageArray.productImages.length;i++){
            if(ImageArray.productImages[i]._id === imageId)
            {
                ImageArray.productImages.splice(i,1)
            }
        }
        setSelectedProduct(ImageArray)
        //Remove Image from server
        DeleteProductImages(selectedProduct._id,imageId).then((data)=>{
            console.log(data)
        })
    }
    
    return (
        <Card>
        <CardHeader title="المنتجات">
            <CardHeaderToolbar>
            <button
                type="button"
                className="btn btn-primary"
                onClick={()=>addNewProductModel()}
            >
             <i class="fas fa-plus"></i>   اضافة منتج
            </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
      
        <Table responsive bordered>
            <thead>
                <tr>
                    {/* <th className="selection-cell-header" data-row-selection="true">
                        <input type="checkbox" style={{display: 'none'}} />
                        <label className="checkbox checkbox-single">
                        <input onChange={()=>showdeleteButtons()} type="checkbox" />
                            <span></span>
                        </label>
                    </th>
                    <th>ID</th> */}
                    <th> اسم المنتج</th>
                    <th>صورة المنتج</th>
                    <th> السعر</th>
                    <th> المخزون</th>
                    <th> نوع المنتج</th>
                    <th> تاريخ الاضافة</th>
                    <th>خيارات</th>
                </tr>
            </thead>
            <tbody>
            {products.length > 0 && 
                products.map((product,index)=>{
                    return (
                        <tr key={"PR-"+index}>
                            {/* <td className="selection-cell">
                                <input type="checkbox" style={{display: 'none'}} />
                                    <label className="checkbox checkbox-single">
                                        <input type="checkbox"/><span></span></label></td>
                            <td>
                                {category._id}
                            </td> */}
                            <td>
                                {product.product_title}
                            </td>
                            <td>
                                {product.productImages.length > 0 && <img width={60}  src={UPLOADURL + "/" + product.productImages[0].product_image} className="rounded"/>}
                                {product.productImages.length == 0 && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                            </td>
                            <td>
                                {product.product_price} {"curruncy_symbole" in configrations && configrations.curruncy_symbole}
                            </td>
                            <td>
                                {product.product_quantity}
                            </td>
                            <td>
                                {product.product_type === 'Normal' && " منتج عادي "}
                                {product.product_type === 'Food' && " منتج اكل  "}
                                {product.product_type === 'Digital' && " منتج رقمي "}
                                {product.product_type === 'DigitalCard' && " بطاقات رقمية "}
                            </td>
                            <td>
                                {product.createdAt}
                            </td>
                            <td>
                            <a
                                title="Edit customer"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={() => setCurrentSelectedProduct(product,"edit")}
                            >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                />
                                </span>
                            </a>
                            <a
                                title="Delete customer"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => setCurrentSelectedProduct(product,"delete")}
                            >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                </span>
                            </a>
                            <a
                                title="View product"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                href={`${isAuthenticated().user.domain}/product/${product._id}`}
                                target="_blank"
                                >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                                />
                                </span>
                            </a>
                            </td>
                        </tr>
                    )
                })
                
            }
            {products.length === 0 && 
                <tr>
                    <td colSpan={5}> لا توجد منتجات مضافة </td>
                </tr>
            }
            </tbody>
        </Table>
        {
            pages.length > 1 && 
            <Pagination>
                {
                    pages.map((page,index)=>{
                        if(page === currentPage)
                            return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                        return (
                            <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                        )
                    })
                }
            </Pagination>
        }    

      </CardBody>
        {/* Add Model */}
        <Modal
            size="lg"
            show={lgShow}
            onHide={()=>{setlgShow(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                اضافة منتج جديد
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>نوع المنتج </Form.Label>
                        <select onChange={(e)=>setProductType(e.target.value)}  required className="form-control">
                            <option></option>
                            <option  key={"product_type-1"} value="Normal">منتج عادي</option>
                            <option  key={"product_type-2"} value="Food">منتج اكل</option>
                            <option  key={"product_type-3"} value="Digital">منتج رقمي</option>
                            <option  key={"product_type-4"} value="DigitalCard"> بطاقات رقمية</option>
                        </select>
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>القسم </Form.Label>
                        <select onChange={(e)=>setProductCategory(e.target.value)}  required className="form-control">
                            <option></option>
                            {categories.length > 0 &&
                                categories.map((category,index)=>{ // child Parent Loop
                                    let subCategoryHolder = []
                                    if(category.children.length > 0){
                                        subCategoryHolder.push(<option  key={"options-"+index} value={category._id}> - {category.category_title}</option>)
                                        category.children.map((subCategory,subIndex)=>{
                                            subCategoryHolder.push(<option  key={"suboptions-"+subIndex} value={subCategory._id}> -- {subCategory.category_title}</option>)
                                        })
                                    }else{
                                        subCategoryHolder.push(<option  key={"options-"+index} value={category._id}>-{category.category_title}</option>)
                                    }
                                    return subCategoryHolder
                                })

                            }
                        </select>
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>اسم المنتج</Form.Label>
                        <Form.Control onChange={(e)=>setProductTitle(e.target.value)}  required  type="text" placeholder="ادخل اسم المنتج" />
                        <Form.Text   className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> الكمية</Form.Label>
                        <Form.Control onChange={(e)=>setProductQuantity(e.target.value)}  required  type="number" placeholder="ادخل الكمية " />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>سعر المنتج</Form.Label>
                        <Form.Control  required onChange={(e)=>setProductPrice(e.target.value)}  type="number" placeholder="ادخل  السعر" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicPassword">
                        <Form.Label>صورة المنتج الرئيسية</Form.Label>
                        <ImageUploader
                        {...props}
                        withIcon={true}
                        onChange={onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                            buttonText={"اختر صورة لرفعها"}
                            label = {" اقصي حجم للصورة 5 ميجا "}
                            fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                            fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                            />
                    </Form.Group> */}
                    <Button variant="primary" type="submit">
                        اضف
                    </Button>
                    <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                </Form>
            </Modal.Body>
        </Modal>
        {/* edit Model */}
        <Modal
            size="lg"
            show={lgEditShow}
            onHide={()=>{setlgEditShow(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                تعديل منتج 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs id="controlled-tab-example" activeKey={selectedTabKey} onSelect={(key)=>setselectedTabKey(key)}>
                    <Tab eventKey="productedit" title="بيانات المنتج الاساسية">
                        <Form noValidate validated={validated} onSubmit={handleSubmitFirst}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>نوع المنتج </Form.Label>
                                <select onChange={(e)=>setProductType(e.target.value)}  required className="form-control" value={ProductType}>
                                    <option></option>
                                    <option  key={"product_type-1"} value="Normal">منتج عادي</option>
                                    <option  key={"product_type-2"} value="Food">منتج اكل</option>
                                    <option  key={"product_type-3"} value="Digital">منتج رقمي</option>
                                    <option  key={"product_type-4"} value="DigitalCard"> بطاقات رقمية</option>
                                </select>
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>القسم </Form.Label>
                                <select onChange={(e)=>setProductCategory(e.target.value)}  required className="form-control" value={ProductCategory}> 
                                    <option></option>
                                    {categories.length > 0 &&
                                        categories.map((category,index)=>{ // child Parent Loop
                                            let subCategoryHolder = []
                                            if(category.children.length > 0){
                                                subCategoryHolder.push(<option  key={"options-"+index} value={category._id}> - {category.category_title}</option>)
                                                category.children.map((subCategory,subIndex)=>{
                                                    subCategoryHolder.push(<option  key={"suboptions-"+subIndex} value={subCategory._id}> -- {subCategory.category_title}</option>)
                                                })
                                            }else{
                                                subCategoryHolder.push(<option  key={"options-"+index} value={category._id}>-{category.category_title}</option>)
                                            }
                                            return subCategoryHolder
                                        })

                                    }
                                </select>
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>الماركة </Form.Label>
                                <select onChange={(e)=>setProductBrand(e.target.value)} className="form-control" value={ProductBrand}> 
                                    <option></option>
                                    {brands.length > 0 &&
                                        brands.map((brand,index)=>{ // child Parent Loop
                                           return <option  key={"brand-"+index} value={brand._id}> {brand.brand_title} </option>
                                        })

                                    }
                                </select>
                            </Form.Group>
                            
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>الشحن </Form.Label>
                                <select onChange={(e)=>setProductShipping(e.target.value)}  required className="form-control" value={ProductShipping}>
                                    <option></option>
                                    <option  key={"product_type-1"} value={true}>يتطلب الشحن </option>
                                    <option  key={"product_type-2"} value={false}>لا يتطلب الشحن </option>
                                </select>
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>        
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>اسم المنتج</Form.Label>
                                <Form.Control onChange={(e)=>setProductTitle(e.target.value)} value={ProductTitle}  required  type="text" placeholder="ادخل اسم المنتج" />
                                <Form.Text   className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>اسم المنتج الفرعي</Form.Label>
                                <Form.Control onChange={(e)=>setProductSubTitle(e.target.value)} value={ProductSubTitle}    type="text" placeholder="ادخل اسم المنتج الفرعي" />
                                <Form.Text   className="text-muted">

                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>اسم المنتج الترويجي</Form.Label>
                                <Form.Control onChange={(e)=>setProductPromoTitle(e.target.value)}  value={ProductPromoTitle}   type="text" placeholder="ادخل اسم المنتج الترويجي" />
                                <Form.Text   className="text-muted">

                                </Form.Text>
                            </Form.Group>


                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>تكلفة المنتج</Form.Label>
                                <Form.Control   onChange={(e)=>setProductCost(e.target.value)} value={ProductCost}  type="number" placeholder="ادخل  سعر التكلفة" />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>سعر المنتج</Form.Label>
                                <Form.Control  required onChange={(e)=>setProductPrice(e.target.value)} value={ProductPrice} type="number" placeholder="ادخل  السعر" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>سعر المنتج بعد الخصم</Form.Label>
                                <Form.Control   onChange={(e)=>setProductDiscountPrice(e.target.value)}  value={ProductDiscountPrice} type="number" placeholder="ادخل  سعر المنتج بعد الخصم" />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>تاريخ انتهاء الخصم</Form.Label>
                                {/* <Form.Control   onChange={(e)=>setProductPrice(e.target.value)}  type="number" placeholder="ادخل  تاريخ انتهاء  الخصم" /> */}
                                <DatePicker 
                                    dateFormat="dd-MM-yyyy"
                                    selected={ProductDiscountPriceEnd === undefined ? '' : new Date(ProductDiscountPriceEnd)}
                                    onChange={date => setProductDiscountPriceEnd(date)}
                                    className="form-control"
                                />  
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>  
                                  
                             
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> وزن المنتج</Form.Label>
                                <Form.Control onChange={(e)=>setProductWieght(e.target.value)} value={ProductWieght}    type="number" placeholder="ادخل وزن المنتج " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>        

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> الكمية</Form.Label>
                                <Form.Control onChange={(e)=>setProductQuantity(e.target.value)}  value={ProductQuantity} required  type="number" placeholder="ادخل الكمية " />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> اقل كمية  للشراء</Form.Label>
                                <Form.Control onChange={(e)=>setProductQuantityMin(e.target.value)} value={ProductQuantityMin}   type="number" placeholder="ادخل اقل كمية  للشراء " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> اقصي كمية  للشراء</Form.Label>
                                <Form.Control onChange={(e)=>setProductQuantityMax(e.target.value)} value={ProductQuantityMax}    type="number" placeholder="ادخل اقصي كمية  للشراء " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> وصف مختصر للمنتج</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e)=>setProductShortDescription(e.target.value)} value={ProductShortDescription}  type="text" placeholder="وصف   مختصر للمنتج " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> وصف المنتج   </Form.Label>
                                <CKEditor
                                    //editor={ ClassicEditor }
                                    config={ {
                                        language: 'ar',
                                        height:400,
                                        //plugins: [],
                                        ckfinder: {
                                            uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                                            options: {
                                                resourceType: 'Images'
                                            },
                                            openerMethod: 'popup'
                                        },
                                        Image:{

                                        },
                                        mediaEmbed: {
                                            // configuration...
                                        }
                                        // plugins: [Image,InsertImage],
                                        // toolbar: [ 'bold', 'italic', 'insertImage' ]
                                    } }
                                    data={ProductDescription}
                                    onChange={(event)=>setProductDescription(event.editor.getData())}
                                    // onReady={ editor => {
                                    //     // You can store the "editor" and use when it is needed.
                                    //     console.log( 'Editor is ready to use!', editor );
                                    // } }
                                    // onChange={ ( event, editor ) => {
                                    //     const data = editor.getData();
                                    //     setProductDescription(data)
                                    //     console.log( { event, editor, data } );
                                    // } }
                                    // onBlur={ ( event, editor ) => {
                                    //     console.log( 'Blur.', editor );
                                    // } }
                                    // onFocus={ ( event, editor ) => {
                                    //     console.log( 'Focus.', editor );
                                    // } }
                                />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> عنوان المنتج في محرك البحث</Form.Label>
                                <Form.Control onChange={(e)=>setProductSeoTitle(e.target.value)}  value={ProductSeoTitle}  type="text" placeholder="عنوان المنتج في محرك البحث " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label> وصف المنتج في محرك البحث</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e)=>setProductSeoDescription(e.target.value)}  value={ProductSeoDescription}  type="text" placeholder="وصف المنتج في محرك البحث " />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>        
                            

                            {/* <Form.Group controlId="formBasicPassword">
                                <Form.Label>صورة المنتج الرئيسية</Form.Label>
                                <ImageUploader
                                {...props}
                                withIcon={true}
                                onChange={onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                    buttonText={"اختر صورة لرفعها"}
                                    label = {" اقصي حجم للصورة 5 ميجا "}
                                    fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                    fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                    />
                            </Form.Group> */}
                            <Button variant="primary" type="submit">
                                تعديل
                            </Button>
                            <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Tab>
                    {/* Normal Product */}
                    {
                        ProductType === "Normal" && 
                        <Tab eventKey="producteditadvanced" title="اعدادات متقدمة">
                            {
                                InputGroups.map((inpu,index)=>{
                                    return <TextComponent
                                                key={"dy-"+index} 
                                                options={inpu.options} 
                                                addNewField={()=>addFieldToGroup(inpu.id)} 
                                                type={inpu.type} 
                                                text={inpu.text} 
                                                id={inpu.id} 
                                                deleteMe={()=>inputGroupClick(inpu.id)} 
                                                changeOptionTitle      = {(e,inputId)=>changeOptionTitle(e,inputId)}
                                                changeOptionType       = {(e,inputId)=>changeOptionType(e,inputId)}
                                                changeV1Option         = {(e,inputIndex,componentId)=>changeV1Option(e,inputIndex,componentId)}
                                                changeV1OptionColor    = {(e,inputIndex,componentId)=>changeV1OptionColor(e,inputIndex,componentId)}
                                                changeV1OptionPicture  = {(e,inputIndex,componentId)=>changeV1OptionPicture(e,inputIndex,componentId)}
                                                changeV2Option         = {(e,inputIndex,componentId)=>changeV2Option(e,inputIndex,componentId)}
                                                changeV3Option         = {(e,inputIndex,componentId)=>changeV3Option(e,inputIndex,componentId)}
                                            />
                                })
                            }
                            <button onClick={addNewItem} className="btn btn-primary btn-block btn-lg mt-3 mb-3">اضافة خيار جديد</button>
                            <div className="row">
                                <Button variant="success" type="submit" onClick={()=>handleSubmitTwo()}>
                                    حفظ
                                </Button>
                                <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                            </div>    
                        </Tab>
                    }
                    
                    
                    <Tab eventKey="productimages" title="صور المنتج">
                        <Form onSubmit={handleSubmitThree}>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>صور المنتج </Form.Label>
                                {FormMessage}
                                {selectedProduct.productImages !== undefined &&  
                                    <div className="row">
                                        {
                                            selectedProduct.productImages.map((image,index)=>{
                                                return (
                                                    <div onClick={()=>RemoveProductImage(image._id)}  className="col-sm text-center containerimg" key={"product-images-"+index}>
                                                        <img width={60}  src={UPLOADURL + "/" + image.product_image} className="rounded imghover" />
                                                        
                                                        <div class="overlay">
                                                            <a href="#" class="icon" title="">
                                                            <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> 
                                }
                                
                                <ImageUploader
                                {...props}
                                withIcon={true}
                                onChange={onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                    buttonText={"اختر صورة او اكثر لرفعها"}
                                    label = {" اقصي حجم للصورة 5 ميجا "}
                                    fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                    fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                    />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                اضافة
                            </Button>
                            <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
        {/* delete Model */}
        <Modal
            size="md"
            show={lgShowDelete}
            onHide={()=>{setlgShowDelete(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                حذف 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>هل انت متاكد من حذف المنتج</span>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                <button type="button" onClick = {()=>removeProduct()} className="btn btn-danger">حذف </button>
            </Modal.Footer>
        </Modal>
    
    </Card>
    )
}