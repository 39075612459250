import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert,
    Badge
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {addCoupon,couponPager,couponsCount,deleteCoupon} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"


export const Coupons = ({props})=>{

    const [couponsCountData,setCouponsCountData] = useState(0)
    const [couponsData,setCouponsData] = useState({})
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const loadCouponsCountData = ()=>{
        couponsCount().then((data)=>{
            setCouponsCountData(data.length)
            var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
            console.log("Lenght" +pagesLength)
            var pagesArray  = []
            for(var i=0;i<pagesLength;i++){
                pagesArray.push(i+1);
            }
            setCurrentPage(1)
            setPages(pagesArray)
        })
    }
    const loadCouponsData = ()=>{
        couponPager(skip,limit).then((data)=>{
            setCouponsData(data)
        })
    }
    useEffect(()=>{
        loadCouponsCountData()
        loadCouponsData()
    },[])
    const nextPage = (pageNumber)=>{
        couponPager((pageNumber-1)*10,limit).then((data)=>{
            if(!data.error){
                setCouponsData(data)
                setCurrentPage(pageNumber)
            }
        })
    }
    //Add 
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false);

    const [coupon_type,set_coupon_type] = useState()
    const [coupon_type_value,set_coupon_type_value] = useState()
    const [coupon_discount_date,set_coupon_discount_date] = useState()
    const [coupon_min_amount,set_coupon_min_amount] = useState()
    const [coupon_usage_number,set_coupon_usage_number] = useState()

    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            addCoupon({coupon_type:coupon_type,coupon_type_value:coupon_type_value,coupon_discount_date:coupon_discount_date,coupon_min_amount:coupon_min_amount,coupon_usage_number:coupon_usage_number}).then((data)=>{
                loadCouponsCountData()
                loadCouponsData()
                set_coupon_type()
                set_coupon_type_value()
                set_coupon_discount_date()
                set_coupon_min_amount()
                set_coupon_usage_number()
                setlgShow(false)
            })
        }
    }
    //delete 
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const [selectedCoupon,setselectedCoupon] = useState({})
    const currentSelectedCoupon = (coupon)=>{
        setselectedCoupon(coupon)
        setlgShowDelete(true)
    }
    const removeCoupon = ()=>{
        deleteCoupon(selectedCoupon._id).then(()=>{
            loadCouponsCountData()
                loadCouponsData()
            setlgShowDelete(false)
        })
    }
    // const [today,set_today] = useState(new Date())
    // console.log(new Date())
    // console.log(new Date("2020-11-28T22:00:00.000+00:00"))
    return (
        <Card>
          <CardHeader title="كوبونات الخصم">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>setlgShow(true)}
              >
              <i class="fas fa-plus"></i>  اضافة كوبون خصم 
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> رقم الكوبون</th>
                        <th>حالة الكوبون </th>
                        <th>نوع الخصم </th>
                        <th>مقدار الخصم </th>
                        <th>أقل قيمة للمشتريات </th>
                        <th>مرات الاستخدام </th>
                        <th> تاريخ الاضافة</th>
                        <th> ينتهي في </th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {couponsData.length > 0 && 
                    couponsData.map((coupon,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td width="15%">
                                    {coupon.coupon_title}
                                </td>
                                <td>
                                    {
                                        new Date(coupon.coupon_discount_date) < new Date() && <Badge pill variant="danger">منتهي</Badge>
                                    }
                                    {
                                        new Date(coupon.coupon_discount_date) > new Date() && <Badge pill variant="success">نشط</Badge>
                                    }
                                    
                                    {/* {coupon.coupon_status === true  && <Badge pill variant="success">نشط</Badge>}
                                    {coupon.coupon_status === false  && <Badge pill variant="danger">منتهي</Badge>} */}
                                </td>
                                <td>
                                    {coupon.coupon_type === 'Fixed' && <Badge pill variant="primary"> قيمة ثابتة </Badge>}
                                    {coupon.coupon_type === 'Percent' && <Badge pill variant="info"> نسبة مئوية </Badge>}
                                </td>
                                <td>
                                    {coupon.coupon_type_value}
                                </td>
                                <td>
                                    {coupon.coupon_min_amount}
                                </td>
                                <td>
                                    {coupon.coupon_usage_number}
                                </td>
                                <td className="text-muted small">
                                    {coupon.createdAt}
                                </td>
                                <td className="text-muted small">
                                    
                                    {coupon.coupon_discount_date}
                                </td>

                                <td>
                                {/* <a
                                    title="Edit Brand"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    // onClick={() => addSelectedBrand(brand,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a> */}
                                <a
                                    title="Delete customer"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => currentSelectedCoupon(coupon)}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {couponsData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد  كوبونات مضافة </td>
                    </tr>
                }
                </tbody>
            </Table>
            {
                pages.length > 1 && 
                <Pagination>
                    {
                        pages.map((page,index)=>{
                            if(page === currentPage)
                                return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                            return (
                                <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            }    
    
          </CardBody>
            {/* Model */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>نوع الخصم</Form.Label>
                            <select className="form-control" onChange={(e)=>set_coupon_type(e.target.value)} value={coupon_type}>
                                <option value="">نوع الخصم</option>
                                <option value="Fixed">قيمة ثابتة</option>
                                <option value="Percent">نسبة مئوية</option>
                            </select>
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>قيمة الخصم</Form.Label>
                            <Form.Control  onChange={(e)=>set_coupon_type_value(e.target.value)} value={coupon_type_value} required  type="number" placeholder="  قيمة الخصم  " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>تاريخ انتهاء الخصم</Form.Label>
                            {/* <Form.Control   onChange={(e)=>setProductPrice(e.target.value)}  type="number" placeholder="ادخل  تاريخ انتهاء  الخصم" /> */}
                            <DatePicker 
                                dateFormat="dd-MM-yyyy"
                                selected={coupon_discount_date === undefined ? '' : new Date(coupon_discount_date)}
                                onChange={date => set_coupon_discount_date(date)}
                                className="form-control"
                                required
                            />  
                            <Form.Text  className="text-muted">
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>اقل قيمة لمجموع المشتريات</Form.Label>
                            <Form.Control  required  type="number" onChange={(e)=>set_coupon_min_amount(e.target.value)} value={coupon_min_amount} placeholder="اقل قيمة لمجموع المشتريات" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>عدد مرات الاستخدام</Form.Label>
                            <Form.Control  required  type="number" onChange={(e)=>set_coupon_usage_number(e.target.value)} value={coupon_usage_number} placeholder=" عدد مرات الاستخدام " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            اضف  
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>    
            <Modal
                size="md"
                show={lgShowDelete}
                onHide={()=>{setlgShowDelete(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    حذف 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>هل انت متاكد من حذف </span>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    <button type="button" onClick = {()=>removeCoupon()} className="btn btn-danger">حذف </button>
                </Modal.Footer>
            </Modal>
        </Card>
    
        
    );
}