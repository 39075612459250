import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../_metronic/_partials/controls";
  import {
    Table,
    Pagination,
    Modal,
    Button,
    Form
} from 'react-bootstrap'
import {useSubheader} from "../../_metronic/layout";
import {changePassword} from '../API/ApiCore'
import {isAuthenticated} from '../API/MarchantAuth'
import Toast from 'react-bootstrap/Toast'


export const Profile = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("تغيير كلمة المرور");

  const [vendorpassword,set_password] = useState('')
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event)=>{
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
          setValidated(true);
          event.stopPropagation();

          
      }
      else{
          if(vendorpassword.length >= 8)
          {
            //Update password
            changePassword({password:vendorpassword}).then((data)=>{
              console.log(data)
            })

            setShow(true)
          }
          else{
            seterrorShow(true)
          }
          
      }

  }
  const {user} = isAuthenticated()//useSelector(state => state.auth);

  const [show, setShow] = useState(false);
  const [errorshow, seterrorShow] = useState(false);
  
  return (
  
  
  
  <Card>
      <CardHeader title="الملف الشخصي">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={handleShow}
          >
            تغيير كلمة المرور
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
      <table class="table table-borderless">
      <tbody>
      <tr>
      <td>الإسم</td>
      <td>{user.name}</td>
      </tr>
      <tr>
      <td>البريد الإلكتروني</td>
      <td>{user.email}</td>
      </tr>
      <tr>
      <td>كلمة المرور</td>
      <td>
      
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
              <Form.Control required value={vendorpassword} onChange={(e)=>set_password(e.target.value.trim())}  type="password" placeholder="تغيير كلمة المرور" />
              <Form.Text className="text-muted">
               8 خانات فأكثر
              </Form.Text>
          </Form.Group>

          <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Body className="text-success">تم حفظ كلمة المرور بنجاح!</Toast.Body>
          </Toast>
          <Toast onClose={() => seterrorShow(false)} show={errorshow} delay={3000} autohide>
            <Toast.Body className="text-danger">يجب أن تكون كلمة المرور 8 خانات فأكثر!</Toast.Body>
          </Toast>


          <button type="submit"  className="btn btn-success btn-lg">حفظ  </button>
        </Form>  
        </td>
        </tr>
        </tbody>
        </table>
      </CardBody>

  </Card>
  
 
  );
};
