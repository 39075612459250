import React,{useState} from 'react'
import {
    Form,
    FormControl,
    InputGroup,
    Button,
    FormFile
} from 'react-bootstrap'
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import InputColor from 'react-input-color';
import ImageUploader from "react-images-upload"
import {UPLOADURL} from '../../API/ApiConfig'
import './TextComponentStyle.css'
const TextComponent = (props)=>{
    const [pictures,setPictures] = useState()
    const onDrop = picture => {
        setPictures(picture);
    };
    return (
        <Card>
        {/* <CardHeader title="">
            <CardHeaderToolbar>
                
            </CardHeaderToolbar>
        </CardHeader> */}
            <CardBody>
                <div className="row">
                    <div className="col-xl-4">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control  type="text" value={props.text} onChange={(e)=>props.changeOptionTitle(e,props.id)}  placeholder="مسمي الخيار" />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-xl-4">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <select className="form-control" onChange={(e)=>props.changeOptionType(e,props.id)} defaultValue={props.type}>
                                <option  key={"option-1"} value="Text">نص </option>
                                <option  key={"option-2"} value="Image"> صورة</option>
                                <option  key={"option-3"} value="Color"> لون</option>
                            </select>
                        </Form.Group>
                    </div>
                    <div className="col-xl-4"><button className="btn btn-danger" onClick={()=>props.deleteMe(props.id)}>حذف</button></div>
                </div>
                {
                    props.options.map((option,index)=>{
                        return (
                            <div className="row" key={"component-"+index}>
                                <div className="col-xl-4">
                                    {
                                        props.type === 'Color' && 
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <Button variant="outline-secondary">
                                                    <InputColor
                                                        initialValue={option.v1 === "" ? '#ffffff' : option.v1}
                                                        onChange={(c)=>props.changeV1OptionColor(c,index,props.id)}
                                                        placement="left"
                                                    />
                                                </Button>
                                            </InputGroup.Prepend>
                                            <FormControl aria-describedby="basic-addon1" value={option.v1} />
                                        </InputGroup>
                                    }
                                    {
                                        props.type === 'Image' && 
                                        <InputGroup className="mb-3">
                                            <InputGroup.Prepend>
                                                <Button variant="outline-secondary">
                                                    <FormFile title="" style={{display:"none"}}/>
                                                    <ImageUploader
                                                        {...props}
                                                        withIcon={false}
                                                        onChange={(e)=>props.changeV1OptionPicture(e,index,props.id)}
                                                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                                        maxFileSize={5242880}
                                                        singleImage={true}
                                                        buttonText={""}
                                                        label = {" "}
                                                        fileSizeError = {""}
                                                        fileTypeError = {""}
                                                    />
                                                </Button>
                                            </InputGroup.Prepend>
                                            <FormControl aria-describedby="basic-addon1" value={option.v1} />
                                            {option.v1 !== "" && <img width={50} src={UPLOADURL + "/" + option.v1} />}
                                        </InputGroup> 
                                    }
                                    {
                                        props.type === 'Text' && 
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control value={option.v1} onChange={(e)=>props.changeV1Option(e,index,props.id)} type="text" placeholder=" الخيار" />
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Form.Group>
                                    }
                                    
                                    
                                </div>
                                <div className="col-xl-4">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control value={option.v2} type="number" onChange={(e)=>props.changeV2Option(e,index,props.id)} placeholder=" السعر الاضافي" />
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="col-xl-4">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control value={option.v3} type="number" onChange={(e)=>props.changeV3Option(e,index,props.id)} placeholder=" الكمية" />
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                            </div>
                        )
                    })
                }
                
            </CardBody>
            <CardFooter>
                <div className="row">
                    <div className="col-xl-12">
                        <button className="btn btn-success btn-block btn-lg" onClick={()=>props.addNewField(props.id)}>اضافة خيار</button>
                    </div>
                </div>
            </CardFooter>
        </Card>
        
    )
}

export default TextComponent