import React,{useState} from 'react'
import {
    Form,
    FormControl,
    InputGroup,
    Button,
    FormFile
} from 'react-bootstrap'
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import InputColor from 'react-input-color';
import ImageUploader from "react-images-upload"
import {UPLOADURL} from '../../API/ApiConfig'

const ShippingDetailsComponent = (props)=>{
    const [pictures,setPictures] = useState()
    const onDrop = picture => {
        setPictures(picture);
    };
    return (
        <Card>
        {/* <CardHeader title="">
            <CardHeaderToolbar>
                
            </CardHeaderToolbar>
        </CardHeader> */}
            <CardBody>
                <div className="row">
                    <div className="col-xl-12">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الدولة</Form.Label>
                            <select className="form-control" value={props.componentData.shipping_countries._id} onChange={(e)=>{props.shipping_countries(e,props.inputId)}}>
                                <option value="">الدولة</option>
                                {props.contriesData.map((country,index)=>{
                                    return <option value={country._id}>{country.country_title}</option>
                                })}
                            </select>
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-xl-12">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>المدينة</Form.Label>
                            {
                                props.componentData.cities.map((city,index)=>{
                                    if(city.checked){
                                        return <div className="form-controll"><input checked type="checkbox" value={city._id} onChange={(e)=>{props.shipping_cities(e,props.inputId)}} /> <Form.Label>{city.country_title}</Form.Label> </div>
                                    }
                                    else{
                                        return <div className="form-controll"><input type="checkbox" value={city._id} onChange={(e)=>{props.shipping_cities(e,props.inputId)}} /> <Form.Label>{city.country_title}</Form.Label> </div>
                                    }
                                    
                                })
                            }
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-xl-12">            
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>سعر الشحن</Form.Label>
                            <Form.Control  type="Number" placeholder=" سعر الشحن" value={props.componentData.shipping_price} onChange={(e)=>props.shipping_price(e,props.inputId)} />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-xl-12">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>مدة الشحن</Form.Label>
                            <Form.Control  type="text" placeholder=" مدة الشحن"  value={props.componentData.shipping_time} onChange={(e)=>props.shipping_time(e,props.inputId)} />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                    </div>    
                        
                    <div className="col-xl-12">
                        <Form.Group controlId="formBasicEmail" >
                            <Form.Label>الدفع عن الاستلام</Form.Label>
                            <select className="form-control"  onChange={(e)=>props.shipping_delivery(e,props.inputId)} value={props.componentData.shipping_delivery} >
                                
                                <option value="true">متاح</option>
                                <option value="false">غير متاح</option>
                                {/* {cities.map((city,index)=>{
                                    return <option value={city._id}>{city.country_title}</option>
                                })} */}
                            </select>
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                    </div>    
                    <button type="button" className="btn btn-danger btn-block btn-md" onClick={()=>props.deleteMe(props.id)}>حذف</button>
                </div>
                
                
            </CardBody>
            {/* <CardFooter>
                <div className="row">
                    <div className="col-xl-12">
                        <button className="btn btn-success btn-block btn-lg" 
                        onClick={()=>props.addNewField(props.id)}
                    >اضافة تسعيرة</button>
                    </div>
                </div>
            </CardFooter> */}
        </Card>
        
    )
}

export default ShippingDetailsComponent