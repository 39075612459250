import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {shippingCompany,AddCompany,allCountries,EditCompany,deleteCompany} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"
import ShippingDetailsComponent from './Components/ShippingDetailsComponent'
export const Shipping = ({props})=>{
    const [contriesData,setCountriesData] = useState([])
    //const [citiesData,setCitiesData]      = useState([])
    const [shippingCompanyData,setShippingCompanyData] = useState(0)
    const [lgShow,setlgShow] = useState(false)
    
    
    const loadShippingCompanyData = ()=>{
        shippingCompany().then((data)=>{
            setShippingCompanyData(data)
        })
    }

    const loadAllCountries = ()=>{
        allCountries().then((data)=>{
            setCountriesData(data)
        })
    }
    
    useEffect(()=>{
        loadShippingCompanyData()
        loadAllCountries()
    },[])
    const [InputGroups,setInputGroups] = useState([])
    const addNewInputGroup = ()=>{
        const newArray = Object.assign([],InputGroups)
        newArray.push({
            id:InputGroups.length+1,
            shipping_countries:{},
            shipping_cities:[],
            shipping_price:0,
            shipping_time:"",
            shipping_delivery:false,
            cities:[]
        })
        setInputGroups(newArray)
    }
    const deleteInput = (id)=>{
        console.log(id);
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                newArray.splice(i,1)
            }
        }
        setInputGroups(newArray)
    }
    const shipping_countries_change= (e,id)=>{
        
        // add Country
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                contriesData.forEach(element => {
                    if(element._id === e.target.value){
                        newArray[i].shipping_countries = {"_id":e.target.value,"country_title":element.country_title}
                        newArray[i].shipping_cities = []
                        newArray[i].cities = element.children
                        if(newArray[i].cities.length){
                            for(var x=0;x<newArray[i].cities.length;x++){
                                newArray[i].cities[x].checked = false
                            }
                        }
                    }
                });
                
            }
        }
        setInputGroups(newArray)
    }
    const shipping_cities_change = (e,id)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                if(newArray[i].cities.length){
                    for(var x=0;x<newArray[i].cities.length;x++){
                        if(e.target.value === newArray[i].cities[x]._id){
                            console.log(newArray[i].cities[x])
                            if(newArray[i].cities[x].checked){
                                newArray[i].cities[x].checked = false
                            }
                            else{
                                newArray[i].cities[x].checked = true
                            }
                        }
                    }
                }
                
            }
        }
        setInputGroups(newArray)
    }
    const shipping_price_change = (e,id)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                newArray[i].shipping_price = e.target.value
            }
        }
        setInputGroups(newArray)
    }
    const shipping_time_change = (e,id)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                newArray[i].shipping_time = e.target.value
            }
        }
        setInputGroups(newArray)
    }
    const shipping_delivery_change = (e,id)=>{
        const newArray = Object.assign([],InputGroups)
        for(var i=0;i<newArray.length;i++){
            if(newArray[i].id === id){
                newArray[i].shipping_delivery = e.target.value
            }
        }
        setInputGroups(newArray)
    }
    //add Company
    const [company_name,set_company_name] = useState()
    const [validated, setValidated] = useState(false)
    const clearPrev = ()=>{
        setlgShow(true)
        set_company_name("")
        setInputGroups([])
    }
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            const newArray = Object.assign([],InputGroups)
            for(var i=0;i<newArray.length;i++){
                for(var x=0;x<newArray[i].cities.length;x++){
                    if(newArray[i].cities[x].checked){
                        newArray[i].shipping_cities.push({"_id":newArray[i].cities[x]._id,"country_title":newArray[i].cities[x].country_title})
                    }
                }
            }
            AddCompany({
                "shipping_compnay":company_name,
                "details":newArray
            }).then((data)=>{
                setInputGroups([])
                setlgShow(false)
                loadShippingCompanyData()
            })
        }

    }

    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            //alert(selectedCompany._id)//shipping_compnay
            EditCompany({_id:selectedCompany._id,shipping_compnay:company_name})
            .then((data)=>{
                setSelectedCompany({})
                setlgShowEdit(false)
                setInputGroups([])
                loadShippingCompanyData()
            })
        }
    }
    //Edit Company
    const [lgShowEdit,setlgShowEdit] = useState(false)
    //Delete company
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const [selectedCompany,setSelectedCompany] = useState({})
    const addSelectedCompany = (company,selectType)=>{
        if(selectType === "delete")
            setlgShowDelete(true)
        else{
            setlgShowEdit(true)
            set_company_name(company.shipping_compnay)
            console.log(company.children.length)
            if(company.children.length){
                const newArray = []//Object.assign([],InputGroups)
                for(var i=0;i<company.children.length;i++){
                    var c = []
                    for(var x=0;x<contriesData.length;x++){
                        if(contriesData[x]._id === company.children[i].shipping_countries._id){
                            c = contriesData[x].children
                            company.children[i].shipping_cities.forEach(element => {
                                for(var ci=0;ci<c.length;ci++){
                                    if(c[ci]._id === element._id){
                                        c[ci].checked = true
                                    }
                                    // else{
                                    //     c[ci].checked = false
                                    // }
                                }
                            });
                        }
                    }
                    newArray.push({
                        id:company.children[i]._id,
                        shipping_countries:company.children[i].shipping_countries,
                        shipping_cities:company.children[i].shipping_cities,
                        shipping_price:company.children[i].shipping_price,
                        shipping_time:company.children[i].shipping_time,
                        shipping_delivery:company.children[i].shipping_delivery,
                        cities:c
                    })
                }
                setInputGroups(newArray)
            }
        }
            

        setSelectedCompany(company)    
    }

    const removeCompany = ()=>{
        deleteCompany(selectedCompany).then((data)=>{
            setlgShowDelete(false)
            loadShippingCompanyData()
        })
        
    }

    return (
        <Card>
          <CardHeader title="طرق الشحن">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>clearPrev()}
              >
                <i class="fas fa-plus"></i>  اضافة شركة شحن 
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> اسم الشركة</th>
                        <th> تاريخ الاضافة</th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {shippingCompanyData.length > 0 && 
                    shippingCompanyData.map((ship,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {ship.shipping_compnay}
                                </td>
                                <td>
                                    {ship.createdAt}
                                </td>
                                <td>
                                <a
                                    title="Edit Company"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => addSelectedCompany(ship,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a>
                                <a
                                    title="Delete Company"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => addSelectedCompany(ship,"delete")}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {shippingCompanyData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد  شركات شحن  </td>
                    </tr>
                }
                </tbody>
            </Table>
              
    
          </CardBody>
        <Modal
            size="lg"
            show={lgShow}
            onHide={()=>{setlgShow(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                اضافة
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>شركة الشحن</Form.Label>
                        <Form.Control value={company_name} required onChange={(e)=>set_company_name(e.target.value)} type="text" placeholder=" شركة الشحن" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>تسعيرة الشحن </Form.Label>
                    </Form.Group>    
                    
                    {InputGroups.map((inp,index)=>{
                        return <ShippingDetailsComponent  
                            contriesData={contriesData} 

                            inputId={inp.id} 
                            deleteMe={()=>deleteInput(inp.id)} 
                            componentData = {inp}
                            shipping_countries = {(e,id)=>shipping_countries_change(e,id)}
                            shipping_cities    = {(e,id)=>shipping_cities_change(e,id)}
                            shipping_price     = {(e,id)=>shipping_price_change(e,id)}
                            shipping_time      = {(e,id)=>shipping_time_change(e,id)}
                            shipping_delivery  = {(e,id)=>shipping_delivery_change(e,id)}
                        />
                    })}
                    <button type="button" className="btn btn-primary btn-block btn-lg mt-3 mb-3" onClick={()=>addNewInputGroup()}>اضافة تسعيرة</button>
                            

                    <Button variant="primary" type="submit">
                        اضف
                    </Button>
                    <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                </Form>
            </Modal.Body>
        </Modal>
          {/* Edit Model */ }
            <Modal
                size="lg"
                show={lgShowEdit}
                onHide={()=>{setlgShowEdit(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>شركة الشحن</Form.Label>
                            <Form.Control value={company_name} required onChange={(e)=>set_company_name(e.target.value)} type="text" placeholder=" شركة الشحن" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>تسعيرة الشحن </Form.Label>
                        </Form.Group>    
                        
                        {InputGroups.map((inp,index)=>{
                            return <ShippingDetailsComponent  
                                contriesData={contriesData} 

                                inputId={inp.id} 
                                deleteMe={()=>deleteInput(inp.id)} 
                                componentData = {inp}
                                shipping_countries = {(e,id)=>shipping_countries_change(e,id)}
                                shipping_cities    = {(e,id)=>shipping_cities_change(e,id)}
                                shipping_price     = {(e,id)=>shipping_price_change(e,id)}
                                shipping_time      = {(e,id)=>shipping_time_change(e,id)}
                                shipping_delivery  = {(e,id)=>shipping_delivery_change(e,id)}
                            />
                        })}
                        {/* <button type="button" className="btn btn-success btn-block btn-lg" onClick={()=>addNewInputGroup()}>اضافة تسعيرة</button> */}
                                

                        <Button variant="primary" type="submit">
                            تعديل
                        </Button>
                        <button type="button" onClick = {()=>{setlgShowEdit(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
          {/* delete Model */}
          <Modal
            size="md"
            show={lgShowDelete}
            onHide={()=>{setlgShowDelete(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                حذف 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>هل انت متاكد من حذف شركة الشحن</span>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                <button type="button" onClick = {()=>removeCompany()} className="btn btn-danger">حذف </button>
            </Modal.Footer>
        </Modal>            
        </Card>
    
        
    );
}