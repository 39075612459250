import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert,
    Dropdown,
    ListGroup,
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {GetDesignBlocks,AddBlock,addImageBlock,editImageBlock,allCategoriesParentChild,EditBlockArrange,DeleteBlock, deleteProductAttribute} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"


export const DesignConfigrations = ({props})=>{

    // const items = [
    //     { number: "1", title: "🇦🇷 Argentina"},
    //     { number: "2", title: "🤩 YASS"},
    //     { number: "3", title: "👩🏼‍💻 Tech Girl"},
    //     { number: "4", title: "💋 Lipstick & Code"},
    //     { number: "5", title: "💃🏼 Latina"},
    // ]
       
    const initialDnDState = {
        draggedFrom: null,
        draggedTo: null,
        isDragging: false,
        originalOrder: [],
        updatedOrder: []
    }
       
    
    const [list, setList] = useState([]);
    const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
    const onDragStart = (event) => {
        const initialPosition = Number(event.currentTarget.dataset.position);
        
        setDragAndDrop({
         ...dragAndDrop,
         draggedFrom: initialPosition,
         isDragging: true,
         originalOrder: list
        });
        
        
        // Note: this is only for Firefox.
        // Without it, the DnD won't work.
        // But we are not using it.
        event.dataTransfer.setData("text/html", '');
    }
       
       // onDragOver fires when an element being dragged
       // enters a droppable area.
       // In this case, any of the items on the list
    const onDragOver = (event) => {
    
        // in order for the onDrop
        // event to fire, we have
        // to cancel out this one
        event.preventDefault();
        
        let newList = dragAndDrop.originalOrder;
        
        // index of the item being dragged
        const draggedFrom = dragAndDrop.draggedFrom; 
        
        // index of the droppable area being hovered
        const draggedTo = Number(event.currentTarget.dataset.position); 
        
        const itemDragged = newList[draggedFrom];
        const remainingItems = newList.filter((item, index) => index !== draggedFrom);
        
            newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
            ];
            
        if (draggedTo !== dragAndDrop.draggedTo){
            setDragAndDrop({
            ...dragAndDrop,
            updatedOrder: newList,
            draggedTo: draggedTo
            })
        }
    
    }
       
    const onDrop = (event) => {
    
        setList(dragAndDrop.updatedOrder);
        
        setDragAndDrop({
        ...dragAndDrop,
        draggedFrom: null,
        draggedTo: null,
        isDragging: false
        });
        console.log(dragAndDrop.draggedFrom)
        console.log(dragAndDrop.draggedTo)
        //Here we update
        if(dragAndDrop.draggedFrom > dragAndDrop.draggedTo){ // Up Drag
            for(var i=dragAndDrop.draggedTo ; i < dragAndDrop.draggedFrom;i++){
                var arrange = list[i].arrange + 1;
                EditBlockArrange(arrange,list[i]._id)
            }

            var arrange = (dragAndDrop.draggedTo)
            EditBlockArrange(arrange,list[dragAndDrop.draggedFrom]._id).then((data)=>{
                console.log(data)
                getItems()
            })
        }
        else{ //DownDrag
            for(var i=dragAndDrop.draggedFrom ; i <= dragAndDrop.draggedTo;i++){
                if(dragAndDrop.draggedFrom === i ){
                    var arrange = (dragAndDrop.draggedTo)
                     EditBlockArrange(arrange,list[dragAndDrop.draggedFrom]._id).then((data)=>{
                         console.log(data)
                    })
                }
                else{
                    var arrange = list[i].arrange - 1;
                    EditBlockArrange(arrange,list[i]._id)
                }
                // var arrange = list[i].arrange - 1;
                // EditBlockArrange(arrange,list[i]._id)
                if(dragAndDrop.draggedTo === i){
                    getItems()
                }
            }
            
        }
        
    }
      
      
    const onDragLeave = () => {
        setDragAndDrop({
        ...dragAndDrop,
        draggedTo: null
        });
        // console.log(dragAndDrop.draggedFrom)
        // console.log(dragAndDrop.draggedTo)
    }
    
    
    // Not needed, just for logging purposes:
    useEffect( ()=>{
        // console.log("Dragged From: ", dragAndDrop && dragAndDrop.draggedFrom);
        // console.log("Dropping Into: ", dragAndDrop && dragAndDrop.draggedTo);
    }, [dragAndDrop])
    
    const getItems = ()=>{
        GetDesignBlocks().then((data)=>{
            setList(data)
        })
    }
    useEffect(()=>{
        getItems()
        loadCategories()
    },[])

    useEffect( ()=>{
        console.log("List updated!");
    }, [list])
    
    const AddFixedBlock = (type)=>{
        AddBlock({
            type:type,
            arrange:list.length
        }).then((data)=>{
            getItems()
        })
    }
    const [categories,getCategories] = useState([])
    const loadCategories = ()=>{
        allCategoriesParentChild().then((data)=>{
            getCategories(data)
        })
    }
    //Fields
    const onDropImage = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const [imageBlocklgShow,setimageBlocklgShow] = useState(false)
    const [validatedImageBlock, setValidatedImageBlock] = useState(false);
    const [pictures, setPictures] = useState();
    const [link,setLink] = useState();
    const handleSubmitImageBlock = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidatedImageBlock(true);
            event.stopPropagation();
        }
        else{
            if(pictures[0])
            {
                addImageBlock({
                    block_image:pictures,
                    content:link,
                    arrange:list.length
                })
                .then((data)=>{
                    getItems()
                    setimageBlocklgShow(false)
                })
            }
        }
    }
    //Add Text Block
    const [title1,setTitle1] = useState()
    const [text1,setText1]   = useState()
    const [link1,setLink1]   = useState()

    const [title2,setTitle2] = useState()
    const [text2,setText2]   = useState()
    const [link2,setLink2]   = useState()

    const [title3,setTitle3] = useState()
    const [text3,setText3]   = useState()
    const [link3,setLink3]   = useState()

    const [title4,setTitle4] = useState()
    const [text4,setText4]   = useState()
    const [link4,setLink4]   = useState()


    const [textBlocklgShow,setTextBlocklgShow] = useState(false)
    const [validatedTextBlock, setValidatedTextBlock] = useState(false);    
    const handleSubmitTextBlock = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidatedTextBlock(true);
            event.stopPropagation();
        }
        else{
            AddBlock({
                type:"TextBlock",
                content:{
                    title1:title1,
                    text1:text1,
                    link1:link1,

                    title2:title2,
                    text2:text2,
                    link2:link2,

                    title3:title3,
                    text3:text3,
                    link3:link3,

                    title4:title4,
                    text4:text4,
                    link4:link4,
                },
                arrange:list.length,
            }).then((data)=>{
                getItems()
                setTextBlocklgShow(false)
            })
        }
    }

    const [productBlocklgShow,setProductBlocklgShow]    = useState(false)
    const [productsDisplayTypes,setProductsDisplayType] = useState()
    const AddProductBlock = (displayType)=>{
        setProductsDisplayType(displayType)
        setProductBlocklgShow(true)
    }
    const [ProductCategory,setProductCategory] = useState()
    const [productBlockTitle,setProductBlockTitle] = useState()
    const [validatedProductBlock, setValidatedProductBlock] = useState(false);    
    const handleSubmitProductBlock = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidatedProductBlock(true);
            event.stopPropagation();
        }
        else{
            
            AddBlock({
                type:productsDisplayTypes,
                content:{
                    category:ProductCategory === undefined ? categories[0]._id : ProductCategory,
                    title:productBlockTitle
                },
                arrange:list.length,
            }).then((data)=>{
                getItems()
                setProductBlocklgShow(false)
            })
        }
    }
    const deleteBlock = (block)=>{
        DeleteBlock(block._id).then((data)=>{
            // var arrange = (dragAndDrop.draggedTo + 1)
            // EditBlockArrange(arrange,list[i]._id)
            //if
            getItems()
        })
    }
    return (
            <Card>
                <CardHeader title="تخصيص الصفحة الرئيسية">
                    <CardHeaderToolbar>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            <i class="fas fa-plus"></i>  اضافة عنصر
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>{AddFixedBlock("Banners")}}><i class="fas fa-angle-left mr-2"></i>  اعلانات متحركة</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{setTextBlocklgShow(true)}}><i class="fas fa-angle-left mr-2"></i> اعلانات نصية</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{setimageBlocklgShow(true)}}><i class="fas fa-angle-left mr-2"></i> بنر اعلانى</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{AddFixedBlock("Brands")}}><i class="fas fa-angle-left mr-2"></i>  العلامات التجارية</Dropdown.Item>
                                
                                <Dropdown.Item onClick={()=>{AddProductBlock("slider")}} className="b_top"><i class="fas fa-angle-left mr-2"></i>عرض متحرك لأحدث المنتجات</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{AddProductBlock("fixed")}}><i class="fas fa-angle-left mr-2"></i>  عرض ثابت لأحدث المنتجات</Dropdown.Item>
                                <Dropdown.Item onClick={()=>{AddProductBlock("grid")}}><i class="fas fa-angle-left mr-2"></i>  عرض شبكي لأحدث المنتجات</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                <div className="small_note mb-2"><i class="far fa-lightbulb"></i> رتب العناصر بالسحب والافلات</div>
                <ListGroup>
                    {list.map( (item, index) => {
                        return(
                            <ListGroup.Item 
                                action onClick={()=>{console.log("Hello")}}
                                key={index}
                                data-position={index}
                                draggable
                                onDragStart={onDragStart}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                                onDragLeave={onDragLeave}
                                className={dragAndDrop && dragAndDrop.draggedTo=== Number(index) ? "dropArea" : "" }
                            >

                                <a
                                    title="Delete Block"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
                                    onClick={() => deleteBlock(item)}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>


                                {item.type === "ImageBlock" && "بنر اعلاني"}
                                {item.type === "Banners" && "اعلانات متحركة"}
                                {item.type === "Brands" && " العلامات التجارية"}
                                {item.type === "TextBlock" && "  اعلانات نصي"}
                                {item.type === "slider" &&   item.content.title + " | عرض متحرك  " }
                                {item.type === "fixed" && item.content.title + "  |  عرض ثابت"}
                                {item.type === "grid" && item.content.title + "  |  عرض شبكي"}

                                <span className="float-right mt-2">
                                <i class="fas fa-arrows-alt"></i>
                                </span>
                             
                            </ListGroup.Item>
                            
                        )
                    })}
                </ListGroup>
                
                </CardBody>
                {/* Image Block */}
                <Modal
                    size="lg"
                    show={imageBlocklgShow}
                    onHide={()=>{setimageBlocklgShow(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        اضافة
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validatedImageBlock} onSubmit={handleSubmitImageBlock}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>رابط البنر الاعلاني</Form.Label>
                                <Form.Control required value={link} onChange={(e)=>setLink(e.target.value)} type="text" placeholder="رابط البنر" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>صورة  البنر</Form.Label>
                                <ImageUploader
                                {...props}
                                withIcon={true}
                                onChange={onDropImage}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                    buttonText={"اختر صورة لرفعها"}
                                    label = {" اقصي حجم للصورة 5 ميجا "}
                                    fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                    fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                    />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                اضف
                            </Button>
                            
                            <button type="button" onClick = {()=>{setimageBlocklgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* TextBlock */}   
                <Modal
                    size="lg"
                    show={textBlocklgShow}
                    onHide={()=>{setimageBlocklgShow(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        اضافة
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validatedTextBlock} onSubmit={handleSubmitTextBlock}>
                            <Card>
                                <CardBody>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>العنوان</Form.Label>
                                        <Form.Control required value={title1} onChange={(e)=>setTitle1(e.target.value)} type="text" placeholder=" العنوان" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>المحتوي</Form.Label>
                                        <Form.Control required value={text1} onChange={(e)=>setText1(e.target.value)} type="text" placeholder="المحتوي" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>الرابط</Form.Label>
                                        <Form.Control value={link1} onChange={(e)=>setLink1(e.target.value)} type="text" placeholder=" الرابط" />
                                    </Form.Group>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>العنوان</Form.Label>
                                        <Form.Control required value={title2} onChange={(e)=>setTitle2(e.target.value)} type="text" placeholder=" العنوان" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>المحتوي</Form.Label>
                                        <Form.Control required value={text2} onChange={(e)=>setText2(e.target.value)} type="text" placeholder="المحتوي" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>الرابط</Form.Label>
                                        <Form.Control value={link2} onChange={(e)=>setLink2(e.target.value)} type="text" placeholder=" الرابط" />
                                    </Form.Group>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>العنوان</Form.Label>
                                        <Form.Control required value={title3} onChange={(e)=>setTitle3(e.target.value)} type="text" placeholder=" العنوان" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>المحتوي</Form.Label>
                                        <Form.Control required value={text3} onChange={(e)=>setText3(e.target.value)} type="text" placeholder="المحتوي" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>الرابط</Form.Label>
                                        <Form.Control value={link3} onChange={(e)=>setLink3(e.target.value)} type="text" placeholder=" الرابط" />
                                    </Form.Group>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>العنوان</Form.Label>
                                        <Form.Control required value={title4} onChange={(e)=>setTitle4(e.target.value)} type="text" placeholder=" العنوان" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>المحتوي</Form.Label>
                                        <Form.Control required value={text4} onChange={(e)=>setText4(e.target.value)} type="text" placeholder="المحتوي" />
                                        <Form.Text className="text-muted">
                                            حقل اجباري
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>الرابط</Form.Label>
                                        <Form.Control value={link4} onChange={(e)=>setLink4(e.target.value)} type="text" placeholder=" الرابط" />
                                    </Form.Group>
                                </CardBody>
                            </Card>

                            
                            
                            <Button variant="primary" type="submit">
                                اضف
                            </Button>
                            
                            <button type="button" onClick = {()=>{setTextBlocklgShow(false)}} className="btn btn-secondary ml-3 ml-3">اغلاق</button>
                        </Form>
                    </Modal.Body>
                </Modal>    
                {/* Products Blocks */}   
                <Modal
                    size="lg"
                    show={productBlocklgShow}
                    onHide={()=>{setProductBlocklgShow(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        اضافة
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validatedProductBlock} onSubmit={handleSubmitProductBlock}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>العنوان</Form.Label>
                                <Form.Control required value={productBlockTitle} onChange={(e)=>setProductBlockTitle(e.target.value)} type="text" placeholder=" العنوان" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>القسم </Form.Label>
                                <select onChange={(e)=>setProductCategory(e.target.value)}  required className="form-control">
                                    {categories.length > 0 &&
                                        categories.map((category,index)=>{ // child Parent Loop
                                            let subCategoryHolder = []
                                            if(category.children.length > 0){
                                                subCategoryHolder.push(<option  key={"options-"+index} value={category._id}> - {category.category_title}</option>)
                                                category.children.map((subCategory,subIndex)=>{
                                                    subCategoryHolder.push(<option  key={"suboptions-"+subIndex} value={subCategory._id}> -- {subCategory.category_title}</option>)
                                                })
                                            }else{
                                                subCategoryHolder.push(<option  key={"options-"+index} value={category._id}>-{category.category_title}</option>)
                                            }
                                            return subCategoryHolder
                                        })

                                    }
                                </select>
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            
                            <Button variant="primary" type="submit">
                                اضف
                            </Button>
                            
                            <button type="button" onClick = {()=>{setProductBlocklgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Modal.Body>
                </Modal> 
            </Card>
            
        

        
    )
}