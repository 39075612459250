import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
  } from "../../_metronic/_partials/controls";
  import {
      Table,
      Pagination,
      Modal,
      Button,
      Form
  } from 'react-bootstrap'
  import ImageUploader from "react-images-upload"
  import SVG from "react-inlinesvg";
  import { toAbsoluteUrl } from "../../_metronic/_helpers";

  import {allCategories,allCategoriesPager,categoriesParents,addNewCategory,editCategory,deleteCategory,deleteCategoryImage} from '../API/ApiCore'
  import {UPLOADURL} from '../API/ApiConfig'

//   import BootstrapTable from 'react-bootstrap-table-next';
import { isAuthenticated } from "../API/MarchantAuth";

export const Categories = ({props}) => {
    const [parentCategories,setParentCategories] = useState([])

    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [pCategory,setPCategory] = useState('empty');
    const [Category,setCategory] = useState('');
    const [validated, setValidated] = useState(false);


    const loadParentCategories = ()=>{
        categoriesParents().then((data)=>{
            console.log(data)
            setParentCategories(data)
        })
    }
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            var parentCategory = pCategory
            if( parentCategory === 'empty'){
                parentCategory = null
            }
                
            addNewCategory({category_title:Category,category_parent:parentCategory,category_image:pictures}).then((data)=>{
                
                if(data.error){
                    setError(true)
                    setSuccess(false)
                }
                else{
                    setError(false)
                    setSuccess(true)
                    setPCategory('empty')
                    setCategory('')
                    setPictures()
                    setlgShow(false)
                    loadParentCategories()
                    loadCategoriesCount(true)
                    loadAllCategories() 
                }
                console.log(data)
            })
        }
        
        

    }
    const categoryParent = (e)=>{
        
        setPCategory(e.target.value)
        
        console.log(e.target.value)
    }
    const categoryTitle = (e)=>{
        setCategory(e.target.value)
    }

    // Mulitiy Uploads
    // const [pictures, setPictures] = useState([]);

    // const onDrop = picture => {
    //     setPictures([...pictures, picture]);
    // };

    const [pictures, setPictures] = useState();

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const handleDismiss = ()=>{
        setError(false)
        setSuccess(false)
    }


    const [categoriesCount,setCategoriesCount] = useState(0)
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const [getCategories,setCategories] = useState([])


    const loadCategoriesCount = (redirect=false)=>{
        allCategories().then((data)=>{
            setCategoriesCount(data.length)
            if(data.length > 10){
                //setPages((data.length % 10)+1)
                var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
                console.log("Lenght" +pagesLength)
                var pagesArray  = []
                for(var i=0;i<pagesLength;i++){
                    pagesArray.push(i+1);
                }
                console.log("current "+currentPage)
                console.log("length "+ data.length)
                if(currentPage !== 1 && redirect === false){
                    if(((currentPage*10)-10) >= data.length){
                        console.log("shifting" + ((currentPage*10)-10))
                        setCurrentPage(currentPage-1)
                        nextPage(currentPage-1)
                    }else{
                        nextPage(currentPage)
                    }
                    
                }else{
                    setCurrentPage(1)
                }
                
                setPages(pagesArray)
            }
            else
                setPages([1])    
        })
    }
    const loadAllCategories = ()=>{
        allCategoriesPager(skip,limit).then((data)=>{
            setCategories(data)
            console.log(data)
        })
    }
    
    useEffect(()=>{
        loadParentCategories()

        loadCategoriesCount()
        loadAllCategories()       
    },[])

    const nextPage = (pageNumber)=>{
        allCategoriesPager((pageNumber-1)*10,limit).then((data)=>{
            setCategories(data)
            setCurrentPage(pageNumber)
        })

    }

    const [lgShow,setlgShow] = useState(false)
    //const [lgClose,setlgClose] = useState(false)
    const addCategory = ()=>{
        setlgShow(true)
        //setlgClose(true)
    }
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const [selectedCategory,setSelectedCategory] = useState({})
    const addSelectedCategory = (category)=>{
        setlgShowDelete(true)
        setSelectedCategory(category)
    }
    const removeCategory = ()=>{
        if(selectedCategory){
            deleteCategory(selectedCategory._id).then((data)=>{
                
                setlgShowDelete(false)
                loadParentCategories()
                loadCategoriesCount()
                loadAllCategories() 
            })
        }
    }

    const [lgEditShow,setlgEditShow] = useState(false)
    const addEditSelectedCategory = (category)=>{
        setSelectedCategory(category)
        if(category.category_parent !== null)
            setPCategory(category.category_parent)
        else
            setPCategory('empty') 
        console.log(category.category_title)       
        setCategory(category.category_title)

        setlgEditShow(true)
        
    }

    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            var parentCategory = pCategory
            if( parentCategory === 'empty'){
                parentCategory = null
            }
                
            editCategory({categoryId:selectedCategory._id,category_title:Category,category_parent:parentCategory,category_image:pictures}).then((data)=>{
                if(data.error){
                    setError(true)
                    setSuccess(false)
                }
                else{
                    setError(false)
                    setSuccess(true)
                    setPCategory('empty')
                    setCategory('')
                    setPictures()
                    //loadParentCategories()
                    setlgShow(false)
                    loadParentCategories()
                    loadCategoriesCount()
                    loadAllCategories() 
                    setlgEditShow(false)
                }
                console.log(data)
            })
        }
        
        

    }

    const [actionButton,setActionButtons] = useState('none')
    const showdeleteButtons = ()=>{
        actionButton === 'none' ? setActionButtons('block') : setActionButtons('none')
    }
    const removeImage = ()=>{
        
        deleteCategoryImage(selectedCategory._id).then((data)=>{
            if(data.error){
                setError(true)
                setSuccess(false)
            }
            else{
                // const cat = Object.assign({},selectedCategory)
                // cat.category_image = 'NaN'
                // setSelectedCategory(cat)

                loadParentCategories()
                loadCategoriesCount()
                loadAllCategories() 
                setlgEditShow(false)
            }
            console.log(data)
        })
    }
  return (
    <Card>
      <CardHeader title="الاقسام">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={()=>addCategory()}
          >
          <i class="fas fa-plus"></i>  اضافة قسم
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
      {/* <form onSubmit={handleSubmit} className="form form-label-right"> */}
        {/* <form className="form form-label-right">
            <div className="form-group row">
                <div className="col-lg-2">
                <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                    // TODO: Change this code
                //   onChange={(e) => {
                //     setFieldValue("status", e.target.value);
                //     handleSubmit();
                //   }}
                //   onBlur={handleBlur}
                //   value={values.status}
                >
                    <option value="">All</option>
                    <option value="0">Susspended</option>
                    <option value="1">Active</option>
                    <option value="2">Pending</option>
                </select>
                <small className="form-text text-muted">
                    <b>Filter</b> by Status
                </small>
                </div>
                <div className="col-lg-2">
                <select
                    className="form-control"
                    placeholder="Filter by Type"
                    name="type"
                //   onBlur={handleBlur}
                //   onChange={(e) => {
                //     setFieldValue("type", e.target.value);
                //     handleSubmit();
                //   }}
                //   value={values.type}
                >
                    <option value="">All</option>
                    <option value="0">Business</option>
                    <option value="1">Individual</option>
                </select>
                <small className="form-text text-muted">
                    <b>Filter</b> by Type
                </small>
                </div>
                <div className="col-lg-2">
                <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                //   onBlur={handleBlur}
                //   value={values.searchText}
                //   onChange={(e) => {
                //     setFieldValue("searchText", e.target.value);
                //     handleSubmit();
                //   }}
                />
                <small className="form-text text-muted">
                    <b>Search</b> in all fields
                </small>
                </div>
            </div>
        </form>   */}
        {/* <div className="form" style={{display:actionButton}}>
            <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
                <div className="col-xl-12">
                    <div className="form-group form-group-inline">
                        <div className="form-label form-label-no-wrap">
                            <label className="font-bold font-danger">
                                <span>Selected records count: <b>1</b></span>
                            </label>
                        </div>
                        <div>
                            <button type="button" className="btn btn-danger font-weight-bolder font-size-sm"><i className="fa fa-trash"></i> Delete All</button>&nbsp;
                            <button type="button" className="btn btn-light-primary font-weight-bolder font-size-sm"><i className="fa fa-stream"></i> Fetch Selected</button>&nbsp;
                            <button type="button" className="btn btn-light-primary font-weight-bolder font-size-sm"><i className="fa fa-sync-alt"></i> Update Status</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <Table responsive bordered>
            <thead>
                <tr>
                    {/* <th className="selection-cell-header" data-row-selection="true">
                        <input type="checkbox" style={{display: 'none'}} />
                        <label className="checkbox checkbox-single">
                        <input onChange={()=>showdeleteButtons()} type="checkbox" />
                            <span></span>
                        </label>
                    </th>
                    <th>ID</th> */}
                    <th> القسم</th>
                    <th>صورة القسم</th>
                    <th>نوع القسم</th>
                    <th> تاريخ الاضافة</th>
                    <th>خيارات</th>
                </tr>
            </thead>
            <tbody>
            {getCategories.length > 0 && 
                getCategories.map((category,index)=>{
                    return (
                        <tr key={"TR-"+index}>
                            {/* <td className="selection-cell">
                                <input type="checkbox" style={{display: 'none'}} />
                                    <label className="checkbox checkbox-single">
                                        <input type="checkbox"/><span></span></label></td>
                            <td>
                                {category._id}
                            </td> */}
                            <td>
                                {category.category_title}
                            </td>
                            <td>
                                {category.category_image !== "NaN" && <img width={60}  src={UPLOADURL + "/" + category.category_image} className="rounded"/>}
                                {category.category_image === "NaN" && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                            </td>
                            <td>
                                {category.category_parent === null && "قسم رئيسي" }
                                {category.category_parent !== null && "قسم فرعي" }
                            </td>
                            <td>
                                {category.createdAt}
                            </td>
                            <td>
                            <a
                                title="Edit customer"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={() => addEditSelectedCategory(category)}
                            >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                />
                                </span>
                            </a>
                            <a
                                title="Delete customer"
                                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                onClick={() => addSelectedCategory(category)}
                            >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                </span>
                            </a>
                            <a
                                title="View category"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                href={`${isAuthenticated().user.domain}/categories/${category._id}`}
                                target="_blank"
                                >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                                />
                                </span>
                            </a>
                            </td>
                        </tr>
                    )
                })
                
            }
            {getCategories.length === 0 && 
                <tr>
                    <td colSpan={5}> لا توجد اقسام مضافة </td>
                </tr>
            }
            </tbody>
        </Table>
        {
            pages.length > 1 && 
            <Pagination>
                {
                    pages.map((page,index)=>{
                        if(page === currentPage)
                            return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                        return (
                            <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                        )
                    })
                }
            </Pagination>
        }    

      </CardBody>
        <Modal
            size="lg"
            show={lgShow}
            onHide={()=>{setlgShow(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                اضافة قسم جديد
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>القسم الرئيسي</Form.Label>
                        <select className="form-control" onChange={(e)=>categoryParent(e)} value={pCategory}>
                            <option value="empty" >بدون قسم رئيسي</option>
                            {parentCategories.length > 0 &&
                                parentCategories.map((category,index)=>{
                                    return (
                                        <option  key={"options-"+index} value={category._id}>{category.category_title}</option>
                                    )
                                })

                            }
                        </select>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>اسم القسم</Form.Label>
                        <Form.Control value={Category} required onChange={(e)=>categoryTitle(e)} type="text" placeholder="ادخل اسم القسم" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>صورة القسم</Form.Label>
                        <ImageUploader
                        {...props}
                        withIcon={true}
                        onChange={onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                            buttonText={"اختر صورة لرفعها"}
                            label = {" اقصي حجم للصورة 5 ميجا "}
                            fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                            fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                            />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        اضف
                    </Button>
                    
                    <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                </Form>
            </Modal.Body>
        </Modal>
        {/* edit Model */}
        <Modal
            size="lg"
            show={lgEditShow}
            onHide={()=>{setlgEditShow(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                تعديل قسم 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>القسم الرئيسي</Form.Label>
                        <select className="form-control" onChange={(e)=>categoryParent(e)} value={pCategory}>
                            <option value="empty" >بدون قسم رئيسي</option>
                        
                            {parentCategories.length > 0 &&
                                parentCategories.map((category,index)=>{
                                    if(category._id !== selectedCategory._id){
                                        return (
                                            <option  key={"options-"+index} value={category._id}>{category.category_title}</option>
                                        )
                                    }
                                    
                                })

                            }
                        </select>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>اسم القسم</Form.Label>
                        <Form.Control value={Category} required onChange={(e)=>categoryTitle(e)} type="text" placeholder="ادخل اسم القسم" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <div className="row">
                            <div className="col-sm"><Form.Label>صورة القسم</Form.Label></div>
                            <div onClick={()=>removeImage()} className="col-sm text-center containerimg">{selectedCategory.category_image !== "NaN" && <img width={60}  src={UPLOADURL + "/" + selectedCategory.category_image} className="rounded" />}
                            
                            <div class="overlay">
                                <a href="#" class="icon" title="">
                                    <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                </a>
                            </div>
                            </div>
                        </div>    
                        <ImageUploader
                        {...props}
                        withIcon={true}
                        onChange={onDrop}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={5242880}
                        singleImage={true}
                            buttonText={"اختر صورة لرفعها"}
                            label = {" اقصي حجم للصورة 5 ميجا "}
                            fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                            fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                            />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        تعديل
                    </Button>

                    <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                </Form>
            </Modal.Body>
        </Modal>
        {/* delete Model */}
        <Modal
            size="md"
            show={lgShowDelete}
            onHide={()=>{setlgShowDelete(false)}}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                حذف القسم
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>هل انت متاكد من حذف القسم</span>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                <button type="button" onClick = {()=>removeCategory()} className="btn btn-danger">حذف القسم</button>
            </Modal.Footer>
        </Modal>
    </Card>

    
  );
};