import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {pagesList,AddPage,EditPage,DeletePage} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"
import CKEditor from 'ckeditor4-react';
import { isAuthenticated } from "../API/MarchantAuth";

export const Pages = ({props})=>{

    const [pagesData,setPagesData] = useState([])

    const loadAllPages = ()=>{
        pagesList().then((data)=>{
            if(data){
                setPagesData(data)
            }
        })
    }
    

    useEffect(()=>{
        loadAllPages()
    },[])
    
    // Add New Page
    const [lgShow,setlgShow] = useState(false)
    const [page_id,set_page_id]       = useState("")
    const [page_title,set_page_title] = useState("")
    const [page_content,set_page_content] = useState("")
    const [page_footer,set_page_footer]   = useState(false)
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            setlgShow(false)
            AddPage({page_title:page_title,page_content:page_content,page_footer:page_footer}).then((data)=>{
                loadAllPages()
                set_page_title("")
                set_page_content("")
                set_page_footer(false)
            })
        }

    }

    const [lgShow2,setlgShow2] = useState(false)
    const AddPageToModifiy = (page,type)=>{
        if(type === "edit"){
            setlgShow2(true)
            set_page_id(page._id)
            set_page_title(page.page_title)
            set_page_content(page.page_content)
            set_page_footer(page.page_footer)
        }
        else{
            //Delete
            setlgShow3(true)
            set_page_id(page._id)
        }
    }


    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            setlgShow2(false)
            EditPage({page_id:page_id,page_title:page_title,page_content:page_content,page_footer:page_footer}).then((data)=>{
                loadAllPages()
                set_page_id("")
                set_page_title("")
                set_page_content("")
                set_page_footer(false)
            })
        }

    }
    const [lgShow3,setlgShow3] = useState(false)
    const removePage = ()=>{
        DeletePage(page_id).then((data)=>{
            loadAllPages()
            set_page_id("")
            set_page_title("")
            set_page_content("")
            setlgShow3(false)
            set_page_footer(false)
        })
    }
    return (
        <Card>
          <CardHeader title="الصفحات التعريفية">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>setlgShow(true)}
              >
              <i class="fas fa-plus"></i>  اضافة صفحة 
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> عنوان الصفحة</th>
                        <th> تاريخ الاضافة</th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {pagesData.length > 0 && 
                    pagesData.map((p,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {p.page_title}
                                </td>
                                
                                
                                <td>
                                    {p.createdAt}
                                </td>
                                <td>
                                <a
                                    title="Edit Page"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => AddPageToModifiy(p,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a>
                                <a
                                    title="Delete Page"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => AddPageToModifiy(p,'delete')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                                <a
                                    title="View Page"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    href={`${isAuthenticated().user.domain}/page/${p._id}/${p.page_title.trim().replace(/ /g,"-")}`}
                                    target="_blank"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")}
                                    />
                                    </span>
                                </a>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {pagesData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد صفحات  مضافة </td>
                    </tr>
                }
                </tbody>
            </Table>
            
          </CardBody>
            {/* Modal */}
            {/* Add Modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>عنوان الصفحة</Form.Label>
                            <Form.Control value={page_title} required onChange={(e)=>set_page_title(e.target.value)} type="text" placeholder="عنوان الصفحة" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                                <Form.Label> المحتوي  </Form.Label>
                                <CKEditor
                                    //editor={ ClassicEditor }
                                    config={ {
                                        language: 'ar',
                                        height:400,
                                        //plugins: [],
                                        ckfinder: {
                                            uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                                            options: {
                                                resourceType: 'Images'
                                            },
                                            openerMethod: 'popup'
                                        },
                                        Image:{

                                        },
                                        mediaEmbed: {
                                            // configuration...
                                        }
                                        // plugins: [Image,InsertImage],
                                        // toolbar: [ 'bold', 'italic', 'insertImage' ]
                                    } }
                                    data={page_content}
                                    onChange={(event)=>set_page_content(event.editor.getData())}
                                />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                            <Form.Label>اضافة لقائمة الفوتر</Form.Label>
                                {
                                    page_footer === true && <Form.Check checked type="checkbox" value={page_footer} onChange={()=>set_page_footer(!page_footer)} />
                                }
                                {
                                    page_footer === false && <Form.Check  type="checkbox" value={page_footer} onChange={()=>set_page_footer(!page_footer)} />
                                }
                            </Form.Group>
                        <Button variant="primary" type="submit">
                            اضف
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Edit Model */}
            <Modal
                size="lg"
                show={lgShow2}
                onHide={()=>{setlgShow2(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>عنوان الصفحة</Form.Label>
                            <Form.Control value={page_title} required onChange={(e)=>set_page_title(e.target.value)} type="text" placeholder="عنوان الصفحة" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                                <Form.Label> المحتوي  </Form.Label>
                                <CKEditor
                                    //editor={ ClassicEditor }
                                    config={ {
                                        language: 'ar',
                                        height:400,
                                        //plugins: [],
                                        ckfinder: {
                                            uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json',
                                            options: {
                                                resourceType: 'Images'
                                            },
                                            openerMethod: 'popup'
                                        },
                                        Image:{

                                        },
                                        mediaEmbed: {
                                            // configuration...
                                        }
                                        // plugins: [Image,InsertImage],
                                        // toolbar: [ 'bold', 'italic', 'insertImage' ]
                                    } }
                                    data={page_content}
                                    onChange={(event)=>set_page_content(event.editor.getData())}
                                />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                            <Form.Label>اضافة لقائمة الفوتر</Form.Label>
                                {
                                    page_footer === true && <Form.Check checked type="checkbox" value={page_footer} onChange={()=>set_page_footer(!page_footer)} />
                                }
                                {
                                    page_footer === false && <Form.Check  type="checkbox" value={page_footer} onChange={()=>set_page_footer(!page_footer)} />
                                }
                            </Form.Group>
                        <Button variant="primary" type="submit">
                            اضف
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow2(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Delete Model */}
            <Modal
                size="md"
                show={lgShow3}
                onHide={()=>{setlgShow3(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    حذف  الصفحة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>هل انت متاكد من حذف الصفحة </span>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick = {()=>{setlgShow3(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    <button type="button" onClick = {()=>removePage()} className="btn btn-danger">حذف  الصفحة</button>
                </Modal.Footer>
            </Modal>
        </Card>
    
        
      );
}