import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {customers,customersCount,allCountries,newCustomer,getSingleCountry} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"

export const Customers = ({props})=>{

    const [customersCountData,setCustomersCountData] = useState(0)
    const [customersData,setCustomersData] = useState({})
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    const [countries,setCountries] = useState([])
    const [cities,setCities] = useState([])
    const loadCustomersCountData = ()=>{
        customersCount().then((data)=>{
            setCustomersCountData(data.length)
            var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
            console.log("Lenght" +pagesLength)
            var pagesArray  = []
            for(var i=0;i<pagesLength;i++){
                pagesArray.push(i+1);
            }
            setCurrentPage(1)
            setPages(pagesArray)
        })
    }
    const loadCustomersData = ()=>{
        customers(skip,limit).then((data)=>{
            setCustomersData(data)
            console.log(data)
        })
    }
    const loadAllCountries = ()=>{
        allCountries().then((data)=>{
            setCountries(data)
        })
    }
    useEffect(()=>{
        loadCustomersCountData()
        loadCustomersData()
        loadAllCountries()
    },[])
    const nextPage = (pageNumber)=>{
        customers((pageNumber-1)*10,limit).then((data)=>{
            if(!data.error){
                setCustomersData(data)
                setCurrentPage(pageNumber)
            }
        })
    }
    //add User
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false)
    const [selectedCountry,setSelectedCountry] = useState()
    const [selectedCity,setSelectedCity] = useState()
    const [customer_name,set_customer_name] = useState("")
    const [customer_phone,set_customer_phone] = useState("")
    const [customer_email,set_customer_email] = useState("")
    const [customer_password,set_customer_password] = useState("")
    const [customer_address,set_customer_address] = useState("")
    const [pictures, setPictures] = useState();
    const [FormMessage,setFormMessage] = useState([])

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            newCustomer({
                name:customer_name,
                phone:customer_phone,
                email:customer_email,
                password:customer_password,
                image:pictures,
                country:selectedCountry,
                city:selectedCity,
                address:customer_address
            })
            .then((data)=>{
                if(!data.error){
                    //console.log(data.error)
                    setlgShow(false)
                    loadCustomersCountData()
        loadCustomersData()
        setFormMessage([])
                }
                else{
                    setFormMessage(
                        [...FormMessage,<Alert key={"alert"} variant={'danger'}  dismissible> {data.error}  </Alert>]
                    )
                }
            })
        }
        
    }
    //countryChange
    const countryChange = (e)=>{
        if(e.target.value !== ""){
            setSelectedCountry(e.target.value)
            // get cities
            countries.forEach(c => {
                if(c._id === e.target.value){
                    setCities(c.children)
                }
            });
        } 
        // else{
        //     setCities()
        // }
        // setSelectedCity()
    }
    const cityChande = (e)=>{
        if(e.target.value !== ""){
            setSelectedCity(e.target.value)
        }
    }
    
    return (
        <Card>
          <CardHeader title="الاعضاء">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>setlgShow(true)}
              >
              <i class="fas fa-plus"></i>  اضافة عضو
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>الصورة</th>
                        <th> الاسم</th>
                        <th>البريد </th>
                        <th>الجوال </th>
                        <th>الدولة </th>
                        <th>المدينة </th>
                        <th>العنوان </th>
                        <th>تاريخ الاضافة</th>
                        {/* <th>خيارات</th> */}
                    </tr>
                </thead>
                <tbody>
                {customersData.length > 0 && 
                    customersData.map((customer,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>{(index+1)+(currentPage*10 ===10 ?0:(currentPage-1)*10)}</td>
                                <td>
                                    {customer.image && <img width={60}  src={UPLOADURL + "/" + customer.image} className="rounded" />}
                                    {customer.image === "" && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                                </td>
                                <td>
                                    {customer.name}
                                </td>
                                <td>
                                    {customer.email}
                                </td>
                                <td>
                                    {customer.phone}
                                </td>
                              

                                <td>
                                    {"country" in customer && customer.country.country_title}
                                    
                                </td>
                                <td>
                                    {"city" in customer && customer.city.country_title}
                                </td>
                                <td>
                                    {customer.address}
                                </td>


                                <td>
                                    {customer.createdAt}
                                </td>
                                {/* <td> */}
                                {/* <a
                                    title="Edit Brand"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    // onClick={() => addSelectedBrand(brand,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a> */}
                                {/* <a
                                    title="Delete customer"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => currentSelectedCoupon(coupon)}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a> */}
                                {/* </td> */}
                            </tr>
                        )
                    })
                    
                }
                {customersData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد  اعضاء  </td>
                    </tr>
                }
                </tbody>
            </Table>
            {
                pages.length > 1 && 
                <Pagination>
                    {
                        pages.map((page,index)=>{
                            if(page === currentPage)
                                return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                            return (
                                <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            }    
    
          </CardBody>
          <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {FormMessage}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الدولة</Form.Label>
                            <select className="form-control" onChange={(e)=>countryChange(e)} value={selectedCountry}>
                                <option value="">الدولة</option>
                                {countries.map((country,index)=>{
                                    return <option value={country._id}>{country.country_title}</option>
                                })}
                            </select>
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>المدينة</Form.Label>
                            <select value={selectedCity} onChange={(e)=>cityChande(e)}  className="form-control">
                                <option value="">المدينة</option>
                                {cities.map((city,index)=>{
                                    return <option value={city._id}>{city.country_title}</option>
                                })}
                            </select>
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الاسم</Form.Label>
                            <Form.Control  onChange={(e)=>set_customer_name(e.target.value)} value={customer_name} required  type="text" placeholder="  الاسم   " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الجوال</Form.Label>
                            <Form.Control  onChange={(e)=>set_customer_phone(e.target.value)} value={customer_phone} required  type="number" placeholder="  الجوال   " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>البريد الالكتروني</Form.Label>
                            <Form.Control  onChange={(e)=>set_customer_email(e.target.value)} value={customer_email} required  type="email" placeholder="  البريد الالكتروني   " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>كلمة المرور</Form.Label>
                            <Form.Control  onChange={(e)=>set_customer_password(e.target.value)} value={customer_password} required  type="password" placeholder="  كلمة المرور   " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>العنوان</Form.Label>
                            <Form.Control  onChange={(e)=>set_customer_address(e.target.value)} value={customer_address} required  type="text" placeholder="  العنوان   " />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                        <Form.Label>صورة العضو</Form.Label>
                            <ImageUploader
                                {...props}
                                withIcon={true}
                                onChange={onDrop}
                                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                maxFileSize={5242880}
                                singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            اضف
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal> 
        </Card>
    
        
    );
}