import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {allbrands,getBrands,addBrand,editBrand,deleteBrand,deleteBrandImage} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"


export const Brands = ({props})=>{

    const [brandsCount,setBrandsCount] = useState(0) // Count ALl Brands
    const [brands,setBrands]           = useState([]) 

    const [skip,setSkip]   = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)

    const loadAllBrandsCount = ()=>{
        allbrands().then((data)=>{
            if(!data.error){
                setBrandsCount(data.length)

                var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
                console.log("Lenght" +pagesLength)
                var pagesArray  = []
                for(var i=0;i<pagesLength;i++){
                    pagesArray.push(i+1);
                }
                setCurrentPage(1)
                setPages(pagesArray)
            }
        })
    }
    const loadAllBrandsPager = ()=>{
        getBrands(skip,limit).then((data)=>{
            if(!data.error){
                setBrands(data)
            }
        })
    }

    useEffect(()=>{
        loadAllBrandsCount()
        loadAllBrandsPager()
    },[])
    const nextPage = (pageNumber)=>{
        getBrands((pageNumber-1)*10,limit).then((data)=>{
            if(!data.error){
                setBrands(data)
                setCurrentPage(pageNumber)
            }
        })
    }
    //Add Brand
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false);
    const [pictures, setPictures] = useState();

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const addNewBrand = ()=>{
        setlgShow(true)
    }
    const [brandTitle,setBrandTitle] = useState('')
    const brandTitleChange = (e)=>{
        setBrandTitle(e.target.value)
    }
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            addBrand({brand_title:brandTitle,brand_image:pictures}).then((data)=>{
                loadAllBrandsCount()
                loadAllBrandsPager()
                setlgShow(false)
                setPictures()
                setBrandTitle('')
            })
        }

    }
    const [selectedBrand,setSelectedBrand] = useState({})
    const addSelectedBrand = (brand,type)=>{
        setSelectedBrand(brand)
        
        if(type === 'edit'){
            setlgEditShow(true)
            setBrandTitle(brand.brand_title)
        }
        if(type === 'delete'){
            setlgShowDelete(true)
        }
    }
    //Edit 
    const [lgEditShow,setlgEditShow] = useState(false)
    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            editBrand({brandId:selectedBrand._id,brand_title:brandTitle,brand_image:pictures}).then((data)=>{
                loadAllBrandsCount()
                loadAllBrandsPager()
                setPictures()
                setBrandTitle('')
                setSelectedBrand({})
                setlgEditShow(false)
            })
        }

    }

    const removeImage = ()=>{
        
        deleteBrandImage(selectedBrand._id).then((data)=>{
            
                // const cat = Object.assign({},selectedCategory)
                // cat.category_image = 'NaN'
                // setSelectedCategory(cat)

                loadAllBrandsCount()
                loadAllBrandsPager()
                setPictures()
                setBrandTitle('')
                setSelectedBrand({})
                setlgEditShow(false)
            console.log(data)
        })
    }

    //Delete
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const removeBrand = ()=>{
        deleteBrand(selectedBrand._id).then((data)=>{
            setlgShowDelete(false)
            setSelectedBrand({})
            loadAllBrandsCount()
            loadAllBrandsPager()
        })
    }

    return (
        <Card>
          <CardHeader title="العلامات التجارية">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>addNewBrand()}
              >
              <i class="fas fa-plus"></i>  اضافة علامة تجارية 
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> العلامة التجارية</th>
                        <th>صورة العلامة </th>
                        <th> تاريخ الاضافة</th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {brands.length > 0 && 
                    brands.map((brand,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {brand.brand_title}
                                </td>
                                <td>
                                    {brand.brand_image !== "NaN" && <img width={60}  src={UPLOADURL + "/" + brand.brand_image} className="rounded"/>}
                                    {brand.brand_image === "NaN" && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                                </td>
                                
                                <td>
                                    {brand.createdAt}
                                </td>
                                <td>
                                <a
                                    title="Edit Brand"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => addSelectedBrand(brand,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a>
                                <a
                                    title="Delete customer"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => addSelectedBrand(brand,'delete')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {brands.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد علامات تجارية مضافة </td>
                    </tr>
                }
                </tbody>
            </Table>
            {
                pages.length > 1 && 
                <Pagination>
                    {
                        pages.map((page,index)=>{
                            if(page === currentPage)
                                return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                            return (
                                <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            }    
    
          </CardBody>
            {/* Modal */}
            {/* Add Modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>اسم العلامة التجارية</Form.Label>
                            <Form.Control value={brandTitle} required onChange={(e)=>brandTitleChange(e)} type="text" placeholder="ادخل اسم العلامة" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>صورة العلامة التجارية</Form.Label>
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            اضف
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Edit Model */}
            <Modal
                size="lg"
                show={lgEditShow}
                onHide={()=>{setlgEditShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    تعديل العلامة التجارية 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>اسم العلامة التجارية</Form.Label>
                            <Form.Control value={brandTitle} required onChange={(e)=>brandTitleChange(e)} type="text" placeholder="ادخل اسم العلامة التجارية" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <div className="row">
                                <div className="col-sm"><Form.Label>صورة العلامة التجارية</Form.Label></div>
                                {selectedBrand.brand_image  !== 'undefined' && <div onClick={()=>removeImage()} className="col-sm text-center containerimg">{selectedBrand.brand_image !== "NaN" && <img width={60}  src={UPLOADURL + "/" + selectedBrand.brand_image} className="rounded" />}
                                
                                
                                <div class="overlay">
                                    <a href="#" class="icon" title="">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                   </a>
                                </div>
                                                        
                                </div>}
                                
                            </div>    
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            تعديل
                        </Button>

                        <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Delete Model */}
            <Modal
                size="md"
                show={lgShowDelete}
                onHide={()=>{setlgShowDelete(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    حذف علامة تجارية
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>هل انت متاكد من حذف العلامة التجارية</span>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    <button type="button" onClick = {()=>removeBrand()} className="btn btn-danger">حذف العلامة التجارية</button>
                </Modal.Footer>
            </Modal>
        </Card>
    
        
      );
}