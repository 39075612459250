import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {BasicConfigrations,BasicConfigrationsUpdate,BasicConfigrationsDeleteImage} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"
import Toast from 'react-bootstrap/Toast'

export const Configrations = ({props})=>{

    const getConfigrations = ()=>{
        BasicConfigrations().then((data)=>{
            if(data){
                set_shop_title(data.shop_title)
                set_shop_discription(data.shop_discription)
                set_shop_logo(data.shop_logo)
                set_facebook_link(data.facebook_link)
                set_twiter_link(data.twiter_link)
                set_instgram_link(data.instgram_link)
                set_youtube_link(data.youtube_link)
                set_snapchat_link(data.snapchat_link)
                set_contact_info(data.contact_info)
                set_shop_email(data.shop_email)
                set_shop_phone(data.shop_phone)
                set_marrof_logo(data.marrof_logo)
                set_curruncy_symbole(data.curruncy_symbole)
                set_return_policy(data.return_policy)
            }
        })
    }
    const [shop_title,set_shop_title] = useState("")
    const [shop_discription,set_shop_discription] = useState("")
    const [shop_logo,set_shop_logo] = useState("")

    const [facebook_link,set_facebook_link] = useState("")
    const [twiter_link,set_twiter_link] = useState("")
    const [instgram_link,set_instgram_link] = useState("")
    const [youtube_link,set_youtube_link] = useState("")
    const [snapchat_link,set_snapchat_link] = useState("")
    
    const [contact_info,set_contact_info] = useState("")
    const [shop_email,set_shop_email] = useState("")
    const [shop_phone,set_shop_phone] = useState("")
    const [marrof_logo,set_marrof_logo] = useState("")
    const [curruncy_symbole,set_curruncy_symbole] = useState("")
    const [return_policy,set_return_policy] = useState("")
    const [validated, setValidated] = useState(false);
    const [pictures, setPictures] = useState();

    useEffect(()=>{
        getConfigrations()
    },[])

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
            seterrorShow(true)
        }
        else{
            BasicConfigrationsUpdate({
                shop_title:shop_title,
                shop_discription:shop_discription,
                shop_logo:pictures,
                facebook_link:facebook_link,
                twiter_link:twiter_link,
                instgram_link:instgram_link,
                youtube_link:youtube_link,
                snapchat_link:snapchat_link,
                contact_info:contact_info,
                shop_email:shop_email,
                shop_phone:shop_phone,
                marrof_logo:marrof_logo,
                curruncy_symbole:curruncy_symbole,
                return_policy:return_policy
            }).then((data)=>{
                if(data)
                {
                    setShow(true)
                    getConfigrations()
                }
            })
        }
    }
    const [show, setShow] = useState(false);
    const [errorshow, seterrorShow] = useState(false);
    
    const removeImageLogo = ()=>{
        BasicConfigrationsDeleteImage().then((data)=>{
            getConfigrations()
        })
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Card>
                <CardHeader title="اعدادات المتجر">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>اسم المتجر <i className="text-danger">*</i></Form.Label>
                            <Form.Control  required value={shop_title} onChange={(e)=>set_shop_title(e.target.value)} type="text" placeholder="اسم المتجر" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label> وصف    المتجر</Form.Label>
                            <Form.Control as="textarea" rows={3} value={shop_discription}  onChange={(e)=>set_shop_discription(e.target.value)}  type="text" placeholder="وصف المتجر" />
                            <Form.Text className="text-muted">
                                مهم لمحركات البحث
                            </Form.Text>
                        </Form.Group> 
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>شعار المتجر</Form.Label>
                            <div className="row">
                                {shop_logo  !== '' && <div onClick={()=>removeImageLogo()} className="col-sm text-center containerimg"><img width={60}  src={UPLOADURL + "/" + shop_logo} className="rounded" />
                                
                                <div class="overlay">
                                    <a href="#" class="icon" title="">
                                        <i class="fa fa-times fa-2x" aria-hidden="true"></i>
                                    </a>
                                </div>
                                
                                </div>}
                            </div>  
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>رمز عملة المتجر <i className="text-danger">*</i></Form.Label>
                            <Form.Control  required value={curruncy_symbole} onChange={(e)=>set_curruncy_symbole(e.target.value)} type="text" placeholder="رمز العملة" />
                            <Form.Text className="text-muted">
                                مثال: ر.س
                            </Form.Text>
                    </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                        <Form.Label>رابط سياسة الاستبدال والارجاع والإلغاء <i className="text-danger">*</i></Form.Label>
                        <Form.Control  required value={return_policy} onChange={(e)=>set_return_policy(e.target.value)} type="text" placeholder="رابط سياسة الاستبدال والارجاع والإلغاء " />
                        <Form.Text className="text-muted">
                            حقل اجباري
                       </Form.Text>
                    </Form.Group>
                </CardBody>
            </Card>
            <Card>
                <CardHeader title="حسابات التواصل الاجتماعي">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> فيس بوك</Form.Label>
                        <Form.Control  value={facebook_link} onChange={(e)=>set_facebook_link(e.target.value)} type="text" placeholder=" رابط فيس بوك" />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>  تويتر</Form.Label>
                        <Form.Control  value={twiter_link}  onChange={(e)=>set_twiter_link(e.target.value)} type="text" placeholder="رابط تويتر" />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>  انستجرام</Form.Label>
                        <Form.Control  value={instgram_link} onChange={(e)=>set_instgram_link(e.target.value)} type="text" placeholder="رابط انستجرام" />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> يوتيوب </Form.Label>
                        <Form.Control  value={youtube_link} onChange={(e)=>set_youtube_link(e.target.value)} type="text" placeholder="رابط اليوتيوب " />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> سناب شات </Form.Label>
                        <Form.Control  value={snapchat_link} onChange={(e)=>set_snapchat_link(e.target.value)} type="text" placeholder=" رابط سناب شات" />
                    </Form.Group>
                        
                </CardBody>
            </Card>

            <Card>
                <CardHeader title="بيانات الدعم و التواصل">
                    <CardHeaderToolbar></CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>بريد الكتروني للمتجر</Form.Label>
                        <Form.Control value={shop_email} onChange={(e)=>set_shop_email(e.target.value)} type="text" placeholder="بريد الكتروني " />
                        <Form.Text className="text-muted">
                            أعلى المتجر
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>رقم الجوال بالصيغة الدولية</Form.Label>
                        <Form.Control value={shop_phone} onChange={(e)=>set_shop_phone(e.target.value)} type="text" placeholder="رقم الجوال " />
                        <Form.Text className="text-muted">
                            أعلى المتجر
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> نبذى تعريفية </Form.Label>
                        <Form.Control as="textarea" rows={3} value={contact_info} onChange={(e)=>set_contact_info(e.target.value)} type="text" placeholder=" بيانات التواصل" />
                        <Form.Text className="text-muted">
                        أسفل المتجر
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label> رابط صفحتك في معروف</Form.Label>
                        <Form.Control rows={3} value={marrof_logo} onChange={(e)=>set_marrof_logo(e.target.value)} type="text" placeholder="" />
                        <Form.Text className="text-muted">مثال: https://maroof.sa/110819</Form.Text>
                    </Form.Group>
            
                    
                </CardBody>
            </Card>
            <Card>
                <CardBody>

                    <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                    
                        <Toast.Body className="text-success">تم حفظ التعديلات بنجاح!</Toast.Body>
                    </Toast>
                    <Toast onClose={() => seterrorShow(false)} show={errorshow} delay={3000} autohide>
                    
                    <Toast.Body className="text-danger"> لم يتم الحفظ!</Toast.Body>
                     </Toast>


                    <Button variant="primary" className="btn btn-success btn-block btn-lg" type="submit">
                        حفظ
                    </Button>
                </CardBody>    
            </Card>
        </Form>
        

        
    )
}