/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{ useState,useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {UPLOADURL} from '../../../../../app/API/ApiConfig';
// import {BasicConfigrations} from '../API/ApiCore'
import {BasicConfigrations} from '../../../../../app/API/ApiCore'
import {isAuthenticated} from    '../../../../../app/API/MarchantAuth';

export function AsideMenuList({ layoutProps }) {

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };
  const getConfigrations = ()=>{
    BasicConfigrations().then((data)=>{
        if(data){
            set_shop_title(data.shop_title)
            set_shop_discription(data.shop_discription)
            set_shop_logo(data.shop_logo)
            set_facebook_link(data.facebook_link)
            set_twiter_link(data.twiter_link)
            set_instgram_link(data.instgram_link)
            set_youtube_link(data.youtube_link)
            set_snapchat_link(data.snapchat_link)
            set_contact_info(data.contact_info)
            set_shop_email(data.shop_email)
            set_shop_phone(data.shop_phone)
        }
    })
}
const [shop_title,set_shop_title] = useState("")
const [shop_discription,set_shop_discription] = useState("")
const [shop_logo,set_shop_logo] = useState("")

const [facebook_link,set_facebook_link] = useState("")
const [twiter_link,set_twiter_link] = useState("")
const [instgram_link,set_instgram_link] = useState("")
const [youtube_link,set_youtube_link] = useState("")
const [snapchat_link,set_snapchat_link] = useState("")

const [contact_info,set_contact_info] = useState("")
const [shop_email,set_shop_email] = useState("")
const [shop_phone,set_shop_phone] = useState("")

  useEffect(()=>{
    getConfigrations()
},[])


  return (
      
      <>

        <div className="row p-3">
          <div className="shop_logo">
            {shop_logo  !== '' && <div className="col-sm"><img width={50}  src={UPLOADURL + "/" + shop_logo} className="rounded" /></div>}
          </div>
          <div className="shop_title">
            {shop_title}
            <div className="mt-2 shop_link"><a href={isAuthenticated().user.domain} target="_blank">رئيسية المتجر</a></div>
            
          </div>
         
        </div>  


        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          
         
        <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")}/>
            </span>
              <span className="menu-text">الرئيسية</span>
            </NavLink>
          </li>


          

       
           {/* categories */}
           <li
              className={`menu-item ${getMenuItemActive("/categories", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/categories">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")}/>
            </span>
              <span className="menu-text">الأقسام</span>
            </NavLink>
          </li>

          {/* End categories */}




          <li
              className={`menu-item ${getMenuItemActive("/products", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/products">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Clothes/T-Shirt.svg")}/>
            </span>
              <span className="menu-text">المنتجات</span>
            </NavLink>
          </li>
      
     
          {/*end::1 Level*/}






          {/* Brands */}
          <li
              className={`menu-item ${getMenuItemActive("/brands", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/brands">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">العلامات التجارية</span>
            </NavLink>
          </li>

          {/* End Brands */}
          {/* Coupon */}

          <li
              className={`menu-item ${getMenuItemActive("/coupons", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/coupons">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")}/>
            </span>
              <span className="menu-text">كوبونات الخصم</span>
            </NavLink>
          </li>
          {/* End Coupon */}

          {/* Slider */}
          <li
              className={`menu-item ${getMenuItemActive("/sliders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/sliders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Pictures1.svg")}/>
            </span>
              <span className="menu-text">الصور المتحركة</span>
            </NavLink>
          </li>
          {/* End Slider */}

          {/* Customers */}
          <li
              className={`menu-item ${getMenuItemActive("/customers", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
              <span className="menu-text">الأعضاء</span>
            </NavLink>
          </li>
          {/* End Customers */}   

          {/* Orders */}
          <li
              className={`menu-item ${getMenuItemActive("/orders", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}/>
            </span>
              <span className="menu-text">الطلبات</span>
            </NavLink>
          </li>

          {/* End Orders */}   

          {/* Configration */}
          <li className="menu-section ">
            <h4 className="menu-text">الإعدادات</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/configrations", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/configrations">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}/>
            </span>
              <span className="menu-text">اعدادات المتجر</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/configrationsdesign", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/configrationsdesign">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Magic.svg")}/>
            </span>
              <span className="menu-text">تصميم المتجر</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/paymentmethods", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/paymentmethods">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")}/>
            </span>
              <span className="menu-text">اعدادات الدفع</span>
            </NavLink>
          </li>
          

          <li
              className={`menu-item ${getMenuItemActive("/shipping", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/shipping">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box3.svg")}/>
            </span>
              <span className="menu-text">اعدادات الشحن</span>
            </NavLink>
          </li>

          {/* End Configration */} 
          <li
              className={`menu-item ${getMenuItemActive("/pages", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/pages">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}/>
            </span>
              <span className="menu-text">صفحات تعريفية</span>
            </NavLink>
          </li>
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
