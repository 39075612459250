import React,{ useState,useEffect} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
  Table,
  Pagination,
  Modal,
  Button,
  Form,
  Tab,
  Tabs,
  Alert,
  Badge
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {orderList,ordersCount,getSingleCountry,updateOrder,customers,couponPager,allProductsPager,BasicConfigrations} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"
import { fontSize } from "@material-ui/system";


export const DashboardPage = ()=> {
  const [skip,setSkip] = useState(0)
  const [limit,setLimit] = useState(10)
  const [ordersData,setOrdersData] = useState({})
  
  const loadOrdersData = ()=>{
    orderList(skip,limit).then((data)=>{
        setOrdersData(data)
    })
  }

  useEffect(()=>{
    loadOrdersData()
    loadCustomersData()
    loadProducts()
    loadConfigrations()
    loadCouponsData()
  },[])

  const [configrations,set_configrations] = useState({})

  const loadConfigrations = ()=>{
      BasicConfigrations().then((data)=>{
        set_configrations(data)
      })
  }

  const [lgShow,setlgShow] = useState(false)
  const [selectedOrder,setSelectedOrder] = useState({})
  const [customerCountry,setCustomerCountry] = useState("")
  const [customerCity,setCustomerCity] = useState("")

  const [orderCountry,setOrderCountry] = useState("")
  const [orderCity,setOrderCity] = useState("")

  const [orderStatus,setOrderStatus] = useState("")

//   const showOrder = (order)=>{
//     setSelectedOrder(order)
//     setOrderStatus(selectedOrder.order_status)
//     setlgShow(true)
//     //selectedOrder.customer[0].country
//     getSingleCountry(order.customer[0].country).then((data)=>{
//         setCustomerCountry(data.country_title)
//     })
//     getSingleCountry(order.customer[0].city).then((data)=>{
//         setCustomerCity(data.country_title)
//     })

//     getSingleCountry(order.country).then((data)=>{
//         setOrderCountry(data.country_title)
//     })
//     getSingleCountry(order.city).then((data)=>{
//         setOrderCity(data.country_title)
//     })

//   }
  const [customersData,setCustomersData] = useState({})
  const loadCustomersData = ()=>{
    customers(skip,limit).then((data)=>{
        setCustomersData(data)
    })
  }

  const [products,getProducts] = useState([])
  const loadProducts = ()=>{
    allProductsPager(skip,limit).then((data)=>{
        getProducts(data)
    })
  }



  const [couponsData,setCouponsData] = useState({})
  const loadCouponsData = ()=>{
    couponPager(skip,limit).then((data)=>{
        setCouponsData(data)
    })
  }



  return (
    <div className="row">
    
        <div className="col-lg-3">
            <Card className="bg-primary text-white">
                <CardBody className="text-center">
                <div className="text-left box-icon"><i className="fas fa-shopping-cart text-white"></i></div>
                <h1 style={{ fontSize: '3rem' }}>{ordersData.length}</h1>
                <p>الطلبات</p>
                </CardBody>
            </Card>
        </div>
        <div className="col-lg-3">
            <Card className="bg-info text-white">
                <CardBody className="text-center">
                <div className="text-left box-icon"><i className="fas fa-user text-white"></i></div>
                <h1 style={{ fontSize: '3rem' }}>{customersData.length}</h1>
                <p>الأعضاء</p>
                </CardBody>
            </Card>
        </div>
        <div className="col-lg-3">
             <Card className="bg-success text-white">
                <CardBody className="text-center">
                <div className="text-left box-icon"><i className="fas fa-tshirt text-white"></i></div>
                <h1 style={{ fontSize: '3rem' }}>{products.length}</h1>
                <p>المنتجات</p>
                </CardBody>
            </Card>
        </div>
        <div className="col-lg-3">
            <Card className="bg-warning text-white">
                <CardBody className="text-center">
                <div className="text-left box-icon"><i className="fas fa-tag text-white"></i></div>
                <h1 style={{ fontSize: '3rem' }}>{couponsData.length}</h1>
                <p>كوبونات</p>
                </CardBody>
            </Card>
        </div>


        <div className="col-lg-6">

            <Card>
          <CardHeader title="أحدث الطلبات">
            <CardHeaderToolbar className="icon-color">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart1.svg")}/>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> رقم الطلب</th>
                        <th>تايخ الطلب </th>
                        <th>حالة الطلب </th>
                        <th>اجمالي الطلب </th>
                       
                    </tr>
                </thead>
                <tbody>
                {ordersData.length > 0 && 
                    ordersData.map((order,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {order._id}
                                </td>
                                <td className="text-muted small">
                                    {order.createdAt}
                                </td>
                                <td className="small">
                                   
                                    {order.order_status === "OnProgress" && <Badge pill variant="primary">جاري العمل علية</Badge>}
                                    {order.order_status === "WaitingPayment" && <Badge pill variant="info">في انتظار الدفع</Badge>}
                                    {order.order_status === "Delivered" && <Badge pill variant="success">تم التسليم</Badge>}
                                    {order.order_status === "Canceled" && <Badge pill variant="danger">تم الالغاء</Badge>}

                                </td>
                                <td>
                                    {order.totalcost} {"curruncy_symbole" in configrations && configrations.curruncy_symbole}
                                </td>
                                
                             
                            </tr>
                        )
                    })
                    
                }
                {ordersData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد  طلبات  </td>
                    </tr>
                }
                </tbody>
            </Table>
          </CardBody>  

   
   
      </Card>
      
        </div>
      
        <div className="col-lg-6">

            <Card>
          <CardHeader title="أحدث الأعضاء">
            <CardHeaderToolbar className="icon-color">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> الاسم</th>
                        <th>الجوال </th>
                        <th> تاريخ الاضافة</th>
                    </tr>
                </thead>
                <tbody>
                {customersData.length > 0 && 
                    customersData.map((customer,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {customer.name}
                                </td>
                             
                                <td>
                                    {customer.phone}
                                </td>
                             
                                <td className="text-muted small">
                                    {customer.createdAt}
                                </td>
                              
                            </tr>
                        )
                    })
                    
                }
                {customersData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا يوجد  أعضاء  </td>
                    </tr>
                }
                </tbody>
            </Table>
          </CardBody>
      </Card>
     
        </div>
     
        <div className="col-lg-12">

            <Card>
        <CardHeader title="أحدث المنتجات">
            <CardHeaderToolbar className="icon-color">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Clothes/T-Shirt.svg")}/>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
      
        <Table responsive bordered>
            <thead>
                <tr>
           
                    <th> اسم المنتج</th>
                    <th>صورة المنتج</th>
                    <th> السعر</th>
                    <th> المخزون</th>
                    <th> نوع المنتج</th>
                    <th> تاريخ الاضافة</th>
                </tr>
            </thead>
            <tbody>
            {products.length > 0 && 
                products.map((product,index)=>{
                    return (
                        <tr key={"PR-"+index}>
                      
                            <td>
                                {product.product_title}
                            </td>
                            <td>
                                {product.productImages.length > 0 && <img width={60}  src={UPLOADURL + "/" + product.productImages[0].product_image} className="rounded"/>}
                                {product.productImages.length == 0 && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                            </td>
                            <td>
                                {product.product_price} {"curruncy_symbole" in configrations && configrations.curruncy_symbole}
                            </td>
                            <td>
                                {product.product_quantity}
                            </td>
                            <td>
                                {product.product_type === 'Normal' && <Badge pill variant="success"> منتج عادي </Badge>}
                                {product.product_type === 'Food' && <Badge pill variant="info"> منتج اكل  </Badge>}
                                {product.product_type === 'Digital' && <Badge pill variant="primary"> منتج رقمي </Badge>}
                                {product.product_type === 'DigitalCard' && <Badge pill variant="danger"> بطاقات رقمية </Badge>}
                            </td>
                            <td className="text-muted small">
                                {product.createdAt}
                            </td>
                      
                        </tr>
                    )
                })
                
            }
            {products.length === 0 && 
                <tr>
                    <td colSpan={5}> لا توجد منتجات مضافة </td>
                </tr>
            }
            </tbody>
        </Table>
      </CardBody>
      </Card>          
    
        </div>
    
    
    
    </div>
  )
       
}
