import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {orderList,ordersCount,getSingleCountry,updateOrder} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"

export const Orders = ({props})=>{

    const [ordersCountData,setordersCountData] = useState(0)
    const [ordersData,setOrdersData] = useState({})
    const [skip,setSkip] = useState(0)
    const [limit,setLimit] = useState(10)
    const [pages,setPages] = useState([])
    const [currentPage,setCurrentPage] = useState(1)
    
    const loadOrdersCountData = ()=>{
        ordersCount().then((data)=>{
            setordersCountData(data.length)
            var pagesLength = (data.length % 10 == 0 ? (data.length / 10) : ( ((data.length - (data.length % 10))  / 10 ) + 1))
            console.log("Lenght" +pagesLength)
            var pagesArray  = []
            for(var i=0;i<pagesLength;i++){
                pagesArray.push(i+1);
            }
            setCurrentPage(1)
            setPages(pagesArray)
        })
    }
    const loadOrdersData = ()=>{
        orderList(skip,limit).then((data)=>{
            setOrdersData(data)
        })
    }
    
    useEffect(()=>{
        loadOrdersCountData()
        loadOrdersData()
    },[])
    const nextPage = (pageNumber)=>{
        orderList((pageNumber-1)*10,limit).then((data)=>{
            if(!data.error){
                setOrdersData(data)
                setCurrentPage(pageNumber)
            }
        })
    }

    
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false);
    const [selectedOrder,setSelectedOrder] = useState({})
    const [customerCountry,setCustomerCountry] = useState("")
    const [customerCity,setCustomerCity] = useState("")

    const [orderCountry,setOrderCountry] = useState("")
    const [orderCity,setOrderCity] = useState("")

    const [orderStatus,setOrderStatus] = useState("")

    const showOrder = (order)=>{
        setSelectedOrder(order)
        setOrderStatus(selectedOrder.order_status)
        setlgShow(true)
        //selectedOrder.customer[0].country
        getSingleCountry(order.customer[0].country).then((data)=>{
            setCustomerCountry(data.country_title)
        })
        getSingleCountry(order.customer[0].city).then((data)=>{
            setCustomerCity(data.country_title)
        })

        getSingleCountry(order.country).then((data)=>{
            setOrderCountry(data.country_title)
        })
        getSingleCountry(order.city).then((data)=>{
            setOrderCity(data.country_title)
        })

    }

    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            if(orderStatus !== "" && orderStatus !== selectedOrder.order_status){
                //Update Order
                updateOrder(selectedOrder._id,{order_status:orderStatus}).then((data)=>{
                    setlgShow(false)
                    loadOrdersCountData()
                    loadOrdersData()
                    setSelectedOrder({})
                    setOrderStatus("")
                    setCustomerCountry("")
                    setCustomerCity("")
                    setOrderCountry("")
                    setOrderCity("")
                })
            }
        }

    }

    return (
        <Card>
          <CardHeader title="الطلبات">
            <CardHeaderToolbar>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={()=>setlgShow(true)}
              >
                اضافة عضو
              </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> رقم الطلب</th>
                        <th>تايخ الطلب </th>
                        <th>حالة الطلب </th>
                        <th>اجمالي الطلب </th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {ordersData.length > 0 && 
                    ordersData.map((order,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                <td>
                                    {order._id}
                                </td>
                                <td>
                                    {order.createdAt}
                                </td>
                                <td>
                                    {order.order_status}
                                </td>
                                <td>
                                    {order.totalcost}
                                </td>
                                
                                <td>
                                <a
                                    title="Edit Brand"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => showOrder(order)}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a>
                                
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {ordersData.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد  طلبات  </td>
                    </tr>
                }
                </tbody>
            </Table>
            {
                pages.length > 1 && 
                <Pagination>
                    {
                        pages.map((page,index)=>{
                            if(page === currentPage)
                                return <Pagination.Item key={"pages-"+index} active>{page}</Pagination.Item>
                            return (
                                <Pagination.Item onClick={()=>nextPage(page)} key={"pages-"+index} >{page}</Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            }    
    
          </CardBody>
          <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        JSON.stringify(selectedOrder) !== JSON.stringify({}) &&
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        
                            <Card>
                                <CardHeader>
                                    بيانات العضو
                                </CardHeader>
                                <CardBody>
                                <Table responsive bordered>
                                    <tr>
                                        <td>اسم العضو</td>
                                        <td>{selectedOrder.customer[0].name}</td>
                                    </tr>
                                    <tr>
                                        <td>بريد العضو</td>
                                        <td>{selectedOrder.customer[0].email}</td>
                                    </tr>
                                    <tr>
                                        <td>الجوال</td>
                                        <td>{selectedOrder.customer[0].phone}</td>
                                    </tr>
                                    <tr>
                                        <td>الدولة</td>
                                        <td>{customerCountry}</td>
                                    </tr>
                                    <tr>
                                        <td>المدينة</td>
                                        <td>{customerCity}</td>
                                    </tr>
                                    <tr>
                                        <td>العنوان</td>
                                        <td>{selectedOrder.customer[0].address}</td>
                                    </tr>
                                </Table>
                                </CardBody>
                            </Card>
                            {
                                selectedOrder.order_shipping !== null && 
                                <Card>
                                    <CardHeader>
                                        بيانات الشحن
                                    </CardHeader>
                                    <CardBody>
                                    <Table responsive bordered>
                                        <tr>
                                            <td>اسم العضو</td>
                                            <td>{selectedOrder.name}</td>
                                        </tr>
                                        <tr>
                                            <td>بريد العضو</td>
                                            <td>{selectedOrder.email}</td>
                                        </tr>
                                        <tr>
                                            <td>الجوال</td>
                                            <td>{selectedOrder.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>الدولة</td>
                                            <td>{orderCountry}</td>
                                        </tr>
                                        <tr>
                                            <td>المدينة</td>
                                            <td>{orderCity}</td>
                                        </tr>
                                        <tr>
                                            <td>العنوان</td>
                                            <td>{selectedOrder.address}</td>
                                        </tr>
                                        <tr>
                                            <td>شركة الشحن</td>
                                            <td>{selectedOrder.order_shipping.shipping_compnay}</td>
                                        </tr>
                                        <tr>
                                            <td>تكلفة الشحن</td>
                                            <td>{selectedOrder.order_shipping.children.shipping_price}</td>
                                        </tr>
                                    </Table>
                                    </CardBody>
                                </Card>
                            }
                            {
                                selectedOrder.ordercart[0].cartitems.length &&
                                <Card>
                                <CardHeader>
                                    المنتجات
                                </CardHeader>
                                <CardBody>
                                <Table responsive bordered>
                                    {
                                        selectedOrder.ordercart[0].cartitems.map((items,index)=>{
                                            return (
                                                <tr className="cart-single">
                                                    <th scope="row">
                                                        <div className="name">
                                                            {
                                                                items.cartproduct[0].productImages.length > 0 && 
                                                                <img width={100} src={UPLOADURL + "/" + items.cartproduct[0].productImages[0].product_image} alt=""/>
                                                            }
                                                            {/* <img src="img/thumb1.jpg" alt=""> */}
                                                            {items.cartproduct[0].product_title}
                                                        </div>
                                                    </th>
                                                    <td>
                                                        <div className="quantity text-center">
                                                            <div className="total-item">
                                                                {items.product_attribute_fiels.length === 0 && <span>- - -</span>}
                                                                {
                                                                    items.product_attribute_fiels && 
                                                                    items.product_attribute_fiels.map((att,index)=>{
                                                                        if(att.attribute_type === "Image"){
                                                                            return <div className="quantity text-center"><img width={50} src={UPLOADURL + "/" + att.attribute_value} alt=""/></div>
                                                                        }
                                                                        else if(att.attribute_type === "Text"){
                                                                            return <div className="quantity text-center">{att.attribute_value}</div>
                                                                        }
                                                                        else if(att.attribute_type === "Color"){
                                                                            return <div className="quantity text-center" style={{width:50,height:50,backgroundColor:att.attribute_value}}><span> </span></div>
                                                                        }
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="quantity text-center">
                                                            <div className="total-item">
                                                            <span>{items.product_quantity}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="subtotal text-center">
                                                            <span>${items.product_price * items.product_quantity}</span>
                                                        </div>
                                                    </td>
                                                  
                                                </tr>

                                            )
                                        })
                                    }
                                    
                                </Table>
                                <Table>
                                    <tr>
                                        <td>الاجمالي</td>
                                        <td>{selectedOrder.totalcost}</td>
                                    </tr>
                                </Table>
                                </CardBody>
                                </Card>
                            }
                            {
                                selectedOrder.order_payment !== null &&
                                <Card>
                                    <CardHeader>
                                        بيانات الدفع
                                    </CardHeader>
                                    <CardBody>
                                    <Table responsive bordered>
                                        {
                                            (selectedOrder.order_payment.payment_type === "Bank" || selectedOrder.order_payment.payment_type === "StcPay") &&
                                            <tr>
                                                <td>التحويل </td>
                                                <td>{selectedOrder.order_payment.payment_type} <img width={500}  src={UPLOADURL + "/" + selectedOrder.order_payment.order_transfer} className="rounded"/> </td>
                                            </tr>
                                        }
                                        
                                        
                                    </Table>
                                    </CardBody>
                                </Card>
                            }
                            
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>حالة الطلب</Form.Label>
                                <select className="form-control" value={orderStatus} onChange={(e)=>setOrderStatus(e.target.value)}>
                                    <option value="WaitingPayment" >في انتظار الدفع</option>
                                    <option value="OnProgress" >جاري العمل عليه</option>
                                    <option value="Delivered" >تم التسليم</option>
                                    <option value="Canceled" >الغاء</option>
                                </select>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                حفظ
                            </Button>
                            
                            <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    }
                    
                </Modal.Body>
            </Modal>
        </Card>
    
        
    );
}