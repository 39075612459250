import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {AddPaymentMethod,EditPaymentMethod,ListPaymentMethod,RemovePaymentMethod,StcPaymentMethod} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"


export const PaymentMethods = ({props})=>{

    const [payment,setPayment] = useState([]) 

    const loadAllMethods = ()=>{
        ListPaymentMethod().then((data)=>{
            setPayment(data)
            data.forEach(element => {
                if(element.payment_type === "StcPay"){
                    set_stc_image(element.payment_info.store_code)
                    set_stc_payment_activation(element.payment_activation)
                }

                if(element.payment_type === "Telr")
                {
                    set_telr_payment_activation(element.payment_activation)
                    set_telr_store_id(element.payment_info.store_id)
                    set_telr_authentication_key(element.payment_info.authentication_key)
                    set_telr_payment_expenses(element.payment_info.payment_expenses)
                }

                if(element.payment_type === "PayPal")
                {
                    set_paypal_payment_activation(element.payment_activation)
                    set_paypal_client_id(element.payment_info.client_id)
                    set_paypal_dollar_price(element.payment_info.dollar_price)
                    set_paypal_payment_expenses(element.payment_info.payment_expenses)
                }

                
            });
        })
    }
    
    useEffect(()=>{
        loadAllMethods()
    },[])
    
    //Add Payment
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false);

    
    const addNewPayment = ()=>{
        setlgShow(true)
    }
    const [bank_title,set_bank_title] = useState()
    const [bank_account_owner,set_bank_account_owner] = useState()
    const [bank_account_number,set_bank_account_number] = useState()
    const [bank_account_ipan,set_bank_account_ipan] = useState()
    const [bank_account_phone,set_bank_account_phone] = useState()
    const [bank_account_active,set_bank_account_active] = useState(false)
    
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            AddPaymentMethod({
                payment_type:"Bank",
                payment_activation:true,//bank_account_active,
                bank_title:bank_title,
                bank_account_owner:bank_account_owner,
                bank_account_number:bank_account_number,
                bank_account_ipan:bank_account_ipan,
                bank_account_phone:bank_account_phone
            }).then((data)=>{
                setlgShow(false)
                loadAllMethods()
            })
        }

    }
    const [selectedPayment,setSelectedPayment] = useState({})
    const addSelectedPayment = (payment,type)=>{
        setSelectedPayment(payment)
        
        if(type === 'edit'){
            setlgEditShow(true)
            set_bank_title(payment.bank_title)
            set_bank_account_owner(payment.bank_account_owner)
            set_bank_account_number(payment.bank_account_number)
            set_bank_account_ipan(payment.bank_account_ipan)
        }
        if(type === 'delete'){
            setlgShowDelete(true)
        }
    }
    // //Edit 
    const [lgEditShow,setlgEditShow] = useState(false)
    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            EditPaymentMethod({
                _id:selectedPayment._id,
                bank_title:bank_title,
                bank_account_owner:bank_account_owner,
                bank_account_number:bank_account_number,
                bank_account_ipan:bank_account_ipan
            })
            .then((data)=>{
                setlgEditShow(false)
                loadAllMethods()
                set_bank_title()
                set_bank_account_owner()
                set_bank_account_number()
                set_bank_account_ipan()
            })
        }

    }
    // //Delete
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const removePayment = ()=>{
        RemovePaymentMethod(selectedPayment._id).then((data)=>{
            setlgShowDelete(false)
            loadAllMethods()
        })
    }
    const [pictures, setPictures] = useState([]);

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };

    const [stc_image,set_stc_image] = useState("")
    const [stc_payment_activation,set_stc_payment_activation] = useState(false)
    const handleSubmitStc = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            if(pictures.length)
            {
                StcPaymentMethod({payment_type:"StcPay",payment_activation:stc_payment_activation,store_code:pictures}).then((data)=>{
                    loadAllMethods()
                })
            }
            else{
                StcPaymentMethod({payment_type:"StcPay",payment_activation:stc_payment_activation}).then((data)=>{
                    loadAllMethods()
                })
            }
        }
    }

    const [telr_store_id,set_telr_store_id] = useState()
    const [telr_authentication_key,set_telr_authentication_key] = useState()
    const [telr_payment_activation,set_telr_payment_activation] = useState(false)
    const [telr_payment_expenses,set_telr_payment_expenses] = useState(0)
    const handleSubmitTelr = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            AddPaymentMethod({
                payment_type:"Telr",
                payment_activation:telr_payment_activation,
                store_id:telr_store_id,
                authentication_key:telr_authentication_key,
                payment_expenses:telr_payment_expenses
            }).then((data)=>{
                //console.log(data)
                loadAllMethods()
            })
        }
    }
    const [paypal_payment_activation,set_paypal_payment_activation] = useState(false)
    const [paypal_client_id,set_paypal_client_id] = useState()
    const [paypal_dollar_price,set_paypal_dollar_price] = useState()
    const [paypal_payment_expenses,set_paypal_payment_expenses] = useState()

    const handleSubmitPayPal = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            console.log(telr_payment_activation)
            AddPaymentMethod({
                payment_type:"PayPal",
                payment_activation:paypal_payment_activation,
                client_id:paypal_client_id,
                dollar_price:paypal_dollar_price,
                payment_expenses:paypal_payment_expenses
            }).then((data)=>{
                console.log(data)
                loadAllMethods()
            })
        }
    }


    return (
        

        <div className="container">
        


        <Card>
                <CardHeader title=" الحسابات البنكية">
                    <CardHeaderToolbar>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={()=>addNewPayment()}
                    >
                        <i class="fas fa-plus"></i>  اضافة حساب  
                    </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                {payment.length > 0 &&
                <Table responsive bordered>
                    <thead>
                        <tr>
                            <th> البنك</th>
                            <th>اسم صاحب الحساب </th>
                            <th>رقم صاحب الحساب </th>
                            <th>رقم  الي بان </th>
                            <th>رقم  الجوال </th>
                            <th> تاريخ الاضافة</th>
                            <th>خيارات</th>
                        </tr>
                    </thead>
                    <tbody>
                    {payment.length > 0 && 
                        payment.map((pay,index)=>{
                            if(pay.payment_type === "Bank"){
                                return (
                                    <tr key={"TR-"+index}>
                                        <td>
                                            {pay.payment_info.bank_title}
                                        </td>
                                        <td>
                                            {pay.payment_info.bank_account_owner}
                                        </td>
                                        <td>
                                            {pay.payment_info.bank_account_number}
                                        </td>
                                        <td>
                                            {pay.payment_info.bank_account_ipan}
                                        </td>
                                        <td>
                                            {pay.payment_info.bank_account_phone}
                                        </td>
                                        <td>
                                            {pay.createdAt}
                                        </td>
                                        <td>
                                        {/* <a
                                            title="Edit Payment"
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                            onClick={() => addSelectedPayment(pay,'edit')}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                            />
                                            </span>
                                        </a> */}
                                        <a
                                            title="Delete Payment"
                                            className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                            onClick={() => addSelectedPayment(pay,'delete')}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                            </span>
                                        </a>
                                        </td>
                                    </tr>
                                )
                            }
                            
                        })
                        
                    }
                    {payment.length === 0 && 
                        <tr>
                            <td colSpan={5}> لا توجد بيانات مضافة </td>
                        </tr>
                    }
                    </tbody>
                </Table>
                }
                
                </CardBody>
                {/* Modal */}
                {/* Add Modal */}
                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={()=>{setlgShow(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        اضافة
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>البنك</Form.Label>
                                <Form.Control required valaue={bank_title} onChange={(e)=>set_bank_title(e.target.value)} type="text" placeholder="ادخل البنك" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>اسم صاحب الحساب</Form.Label>
                                <Form.Control required  type="text" value={bank_account_owner} onChange={(e)=>set_bank_account_owner(e.target.value)} placeholder="ادخل اسم صاحب الحساب" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>رقم الحساب</Form.Label>
                                <Form.Control required  type="text" value={bank_account_number} onChange={(e)=>set_bank_account_number(e.target.value)} placeholder="ادخل رقم الحساب" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>رقم الاي بان</Form.Label>
                                <Form.Control required  type="text" value={bank_account_ipan} onChange={(e)=>set_bank_account_ipan(e.target.value)} placeholder="ادخل رقم الاي بان" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>الجوال</Form.Label>
                                <Form.Control required  type="text" value={bank_account_phone} onChange={(e)=>set_bank_account_phone(e.target.value)} placeholder="رقم الجوال" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            {/* <Form.Group controlId="formBasicPassword">
                                <Form.Label>حالة الدفع</Form.Label>
                                {
                                    bank_account_active === true && <Form.Control checked type="checkbox" value={bank_account_active} onChange={()=>set_bank_account_active(!bank_account_active)} />
                                }
                                {
                                    bank_account_active === false && <Form.Control type="checkbox" value={bank_account_active} onChange={()=>set_bank_account_active(!bank_account_active)} />
                                }
                                
                            </Form.Group> */}
                            <Button variant="primary" type="submit">
                                اضف
                            </Button>
                            
                            <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Edit Model */}
                <Modal
                    size="lg"
                    show={lgEditShow}
                    onHide={()=>{setlgEditShow(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        اضافة
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>البنك</Form.Label>
                                <Form.Control required valaue={bank_title} onChange={(e)=>set_bank_title(e.target.value)} type="text" placeholder="ادخل البنك" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>اسم صاحب الحساب</Form.Label>
                                <Form.Control required  type="text" valaue={bank_account_owner} onChange={(e)=>set_bank_account_owner(e.target.value)} placeholder="ادخل اسم صاحب الحساب" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>رقم الحساب</Form.Label>
                                <Form.Control required  type="text" valaue={bank_account_number} onChange={(e)=>set_bank_account_number(e.target.value)} placeholder="ادخل رقم الحساب" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>رقم الاي بان</Form.Label>
                                <Form.Control required  type="text" valaue={bank_account_ipan} onChange={(e)=>set_bank_account_ipan(e.target.value)} placeholder="ادخل رقم الاي بان" />
                                <Form.Text className="text-muted">
                                    حقل اجباري
                                </Form.Text>
                            </Form.Group>
                            
                            <Button variant="primary" type="submit">
                                اضف
                            </Button>
                            
                            <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Delete Model */}
                <Modal
                    size="md"
                    show={lgShowDelete}
                    onHide={()=>{setlgShowDelete(false)}}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                        حذف
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>هل انت متاكد من الحذف</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                        <button type="button" onClick = {()=>removePayment()} className="btn btn-danger">حذف </button>
                    </Modal.Footer>
                </Modal>
            </Card>
       

            <Card>
                <CardHeader title="Stc Pay">
                    <CardHeaderToolbar>
                    <a target="_blank" href="https://stcpay.com.sa"><img className="pay_logo" src={toAbsoluteUrl("/media/pay/stc-c.png")} alt="stc pay" /></a>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Form noValidate validated={validated} onSubmit={handleSubmitStc}>    
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>صورة رمز الــ QR الثابت </Form.Label>
                            {stc_image  !== 'undefined' && <div className="col-sm">{stc_image !== "" && <img width={60}  src={UPLOADURL + "/" + stc_image} className="rounded" />}</div>}
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>تفعيل/تعطيل طريقة الدفع</Form.Label>
                            {
                                stc_payment_activation === true && <Form.Check checked type="checkbox" value={stc_payment_activation} onChange={()=>set_stc_payment_activation(!stc_payment_activation)} />
                            }
                            {
                                stc_payment_activation === false && <Form.Check type="checkbox" value={stc_payment_activation} onChange={()=>set_stc_payment_activation(!stc_payment_activation)} />
                            }
                            
    
                        </Form.Group>
                        <Button variant="primary" className="btn btn-success btn-lg" type="submit">
                        حفظ
                        </Button>
                    </Form>    
                </CardBody>
            </Card>

           
            


            <Card>
                <CardHeader title=" Telr Account">
                <CardHeaderToolbar>
                <a target="_blank" href="https://telr.com"><img className="pay_logo" src={toAbsoluteUrl("/media/pay/telr-c.png")} alt="logo" /></a>
                </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                <div className="mb-5">
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/mada-c.png")} alt="mada" />
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/sadad-c.png")} alt="sadad" />
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/master-c.png")} alt="mastercard" />
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/visa-c.png")} alt="visa" />
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/stc-c.png")} alt="stc pay" />
                <img className="pay_logo" src={toAbsoluteUrl("/media/pay/apple-c.png")} alt="apple pay" />
                </div>

                <Form noValidate validated={validated} onSubmit={handleSubmitTelr}>    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Store ID</Form.Label>
                        <Form.Control  required value={telr_store_id} onChange={(e)=>set_telr_store_id(e.target.value)}  type="text" placeholder="Store ID" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Authentication Key</Form.Label>
                        <Form.Control  required value={telr_authentication_key} onChange={(e)=>set_telr_authentication_key(e.target.value)} type="text" placeholder="Authentication Key" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicEmail">
                        <Form.Label>نسبة التكاليف الاضافة من اجمالي المشتريات % </Form.Label>
                        <Form.Control  required value={telr_payment_expenses} onChange={(e)=>set_telr_payment_expenses(e.target.value)} type="text" placeholder="" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group> */}
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>تفعيل/تعطيل طريقة الدفع</Form.Label>
                            {
                                telr_payment_activation === true && <Form.Check checked type="checkbox" value={telr_payment_activation} onChange={()=>set_telr_payment_activation(!telr_payment_activation)} />
                            }
                            {
                                telr_payment_activation === false && <Form.Check type="checkbox" value={telr_payment_activation} onChange={()=>set_telr_payment_activation(!telr_payment_activation)} />
                            }
                            
                        </Form.Group>
                        <Button variant="primary" className="btn btn-success btn-lg" type="submit">
                        حفظ
                        </Button>
                    </Form>
                </CardBody>
            </Card>




            {/* <Card>
            <CardHeader title=" Paypal Account">
            <CardHeaderToolbar>
              
            </CardHeaderToolbar>
          </CardHeader>
            <CardBody>
          
                <Form noValidate validated={validated} onSubmit={handleSubmitPayPal}>    
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control  required value={paypal_client_id} onChange={(e)=>set_paypal_client_id(e.target.value)}  type="text" placeholder="Client ID" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Dollar Price For SAR</Form.Label>
                        <Form.Control  required value={paypal_dollar_price} onChange={(e)=>set_paypal_dollar_price(e.target.value)} type="text" placeholder="Dollar Price For SAR" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>نسبة التكاليف الاضافة من اجمالي المشتريات % </Form.Label>
                        <Form.Control  required value={paypal_payment_expenses} onChange={(e)=>set_paypal_payment_expenses(e.target.value)} type="text" placeholder="" />
                        <Form.Text className="text-muted">
                            حقل اجباري
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>حالة الدفع</Form.Label>
                        {
                            paypal_payment_activation === true && <Form.Control checked type="checkbox" value={paypal_payment_activation} onChange={()=>set_paypal_payment_activation(!paypal_payment_activation)} />
                        }
                        {
                            paypal_payment_activation === false && <Form.Control type="checkbox" value={paypal_payment_activation} onChange={()=>set_paypal_payment_activation(!paypal_payment_activation)} />
                        }
                        
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        اضف
                    </Button>
                </Form>
            </CardBody>
          </Card>   */}




    </div>
        
      );
}