import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";

import {Categories} from "./pages/Categories";
import {CategoriesAdd} from "./pages/CategoriesAdd";
import {Products} from "./pages/Products"
import {Sliders} from "./pages/Sliders"
import {Brands} from "./pages/Brands"
import {Customers} from './pages/Customers'
import { Coupons } from "./pages/Coupons"
import {Orders} from './pages/Orders'
import {Shipping} from './pages/Shipping'
import {PaymentMethods} from './pages/PaymentMethods'
import {Configrations}  from './pages/Configrations'
import {DesignConfigrations} from './pages/DesignConfigrations'
import {Profile} from './pages/Profile'
import {Pages} from './pages/Pages'

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard"           component={DashboardPage}/>
                <ContentRoute path="/categories"          component={Categories}/>
                {/* <ContentRoute path="/categories/add" component={CategoriesAdd}/> */}
                <ContentRoute path="/products"            component={Products} />
                <ContentRoute path="/brands"              component={Brands} />
                <ContentRoute path="/sliders"             component={Sliders} />
                <ContentRoute path="/customers"           component={Customers} />
                <ContentRoute path="/coupons"             component={Coupons} />
                <ContentRoute path="/orders"              component={Orders} />
                <ContentRoute path="/shipping"            component={Shipping} />
                <ContentRoute path="/paymentmethods"      component={PaymentMethods} />
                <ContentRoute path="/configrations"       component={Configrations} />
                <ContentRoute path="/configrationsdesign" component={DesignConfigrations} />
                <ContentRoute path="/profile"             component={Profile} />
                <ContentRoute path="/pages"               component={Pages} />
                
                {/* <ContentRoute path="/builder" component={BuilderPage}/>
                <ContentRoute path="/my-page" component={MyPage}/>
                <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to="error/error-v1"/>

                

            </Switch>
        </Suspense>
    );
}
