import {APIURL,UPLOADURL} from './ApiConfig'
import {isAuthenticated} from './MarchantAuth'
export const signIn = (user)=>{ //front/signin
    return fetch(`${APIURL}/signin`,{
        method:'POST',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const changePassword = (newpassword)=>{
    return fetch(`${APIURL}/changepassword`,{
        method:'POST',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(newpassword)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
//list parents categories

export const categoriesParents = ()=>{
    return fetch(`${APIURL}/category/parents`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const addNewCategory = (category)=>{
    const fd = new FormData();
    fd.append('category_title',category.category_title)
    if(category.category_parent !== null)
        fd.append('category_parent',category.category_parent)
    if(category.category_image)    
        fd.append('category_image',category.category_image[0])

    return fetch(`${APIURL}/category/create`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const editCategory = (category)=>{
    const fd = new FormData();
    fd.append('category_title',category.category_title)
    if(category.category_parent !== null)
        fd.append('category_parent',category.category_parent)
    if(category.category_image)    
        fd.append('category_image',category.category_image[0])

    console.log(category)
        
    return fetch(`${APIURL}/category/${category.categoryId}`,{
        method:'PUT',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const allCategories = ()=>{ //http://localhost:8000/api/category
    return fetch(`${APIURL}/category`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const allCategoriesPager = (skip,limit)=>{
    return fetch(`${APIURL}/category/pager/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const deleteCategory = (categoryId)=>{
    return fetch(`${APIURL}/category/${categoryId}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const deleteCategoryImage = (categoryId)=>{
    return fetch(`${APIURL}/category-removeimage/${categoryId}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

//category/parentchild
export const allCategoriesParentChild = ()=>{ //http://localhost:8000/api/category
    return fetch(`${APIURL}/category/parentchild`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const productListCount = ()=>{
    return fetch(`${APIURL}/product/list/count`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const allProductsPager = (skip,limit)=>{
    return fetch(`${APIURL}/product/list/pager/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
//new product http://localhost:8000/api/product/create
export const addNewProduct = (product)=>{
    // const fd = new FormData();
    // fd.append('category',product.category)
    // fd.append('product_title',product.product_title)
    // fd.append('product_price',product.product_price)
    // fd.append('product_quantity',product.product_quantity)
    // fd.append('product_type',product.product_type)
    // if(product.product_image)    
    //     fd.append('product_image',product.product_image[0])

    return fetch(`${APIURL}/product/create`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(product)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const editProduct = (product)=>{
    return fetch(`${APIURL}/product/${product._id}`,{
        method:'PUT',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(product)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const productAtrributes = (productId,attributes)=>{ //http://localhost:8000/api/product/attribute/5f6682077fcde421cb78308e
    return fetch(`${APIURL}/product/attribute/${productId}`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(attributes)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
///product/attribute/:productId/:attributeId
export const deleteProductAttribute =  (productId,attributeId)=>{
    return fetch(`${APIURL}/product/attribute/${productId}/${attributeId}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const singleImage = (image)=>{//http://localhost:8000/api/product/singleImage
    const fd = new FormData();
    if(image)    
        fd.append('image',image)
    return fetch(`${APIURL}/product/singleImage`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

// Add Product Images
export const addProductImages = (productid,image)=>{
    const fd = new FormData();
    
    fd.append('product_image',image)
    return fetch(`${APIURL}/product/image/${productid}`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const DeleteProductImages = (productid,imageId)=>{
    
    return fetch(`${APIURL}/product/image/${productid}/${imageId}`,{
        method:'DELETE',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        //body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const deleteProduct =  (product)=>{
    return fetch(`${APIURL}/product/${product._id}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

/** Brands */
//brands api/brand
export const allbrands = ()=>{ //http://localhost:8000/api/brands
    return fetch(`${APIURL}/brand`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const getBrands = (skip,limit)=>{//brand/list?skip=0&limit=10
    return fetch(`${APIURL}/brand/pager/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const addBrand = (brand)=>{
    const fd = new FormData();
    fd.append('brand_title',brand.brand_title)

    if(brand.brand_image)    
        fd.append('brand_image',brand.brand_image[0])
    return fetch(`${APIURL}/brand/create`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const editBrand = (brand)=>{
    const fd = new FormData();
    fd.append('brand_title',brand.brand_title)

    if(brand.brand_image)    
        fd.append('brand_image',brand.brand_image[0])
    return fetch(`${APIURL}/brand/${brand.brandId}`,{
        method:'PUT',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const deleteBrand = (brandId)=>{
    return fetch(`${APIURL}/brand/${brandId}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const deleteBrandImage = (brandId)=>{
    return fetch(`${APIURL}/brand-removeimage/${brandId}`,{
        method:'DELETE',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}


/** Slider */
export const getSliders = ()=>{
    return fetch(`${APIURL}/slider`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const addSlider = (slider)=>{
    const fd = new FormData();
    if(slider.slider_link)
        fd.append('slider_link',slider.slider_link)

    if(slider.slider_image)    
        fd.append('slider_image',slider.slider_image[0])

    return fetch(`${APIURL}/slider`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const editSlider = (slider)=>{
    const fd = new FormData();
    if(slider.slider_link)
        fd.append('slider_link',slider.slider_link)

    if(slider.slider_image)    
        fd.append('slider_image',slider.slider_image[0])

    return fetch(`${APIURL}/slider/${slider._id}`,{
        method:'PUT',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const deleteSlider = (sliderId)=>{
    return fetch(`${APIURL}/slider/${sliderId}`,{
        method:'DELETE',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}
// Coupons
export const addCoupon = (coupon)=>{
    return fetch(`${APIURL}/coupons`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(coupon)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const couponsCount = ()=>{//randomNumber
    return fetch(`${APIURL}/coupons`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        }
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}
export const couponPager = (skip,limit)=>{//coupons/pager
    return fetch(`${APIURL}/coupons/pager/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const deleteCoupon = (couponId)=>{
    return fetch(`${APIURL}/coupons/${couponId}`,{
        method:'Delete',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const customersCount = ()=>{//customers
    return fetch(`${APIURL}/customers`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const customers = (skip,limit)=>{//customers/pager
    return fetch(`${APIURL}/customers/pager/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

//countries
export const allCountries = ()=>{
    return fetch(`${APIURL}/countries`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}///customer/newuser

export const getSingleCountry = (countryId)=>{
    ///countries/:countryId
    return fetch(`${APIURL}/countries/${countryId}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const newCustomer = (customer)=>{
    const fd = new FormData();
    fd.append('name',customer.name)
    fd.append('username',customer.email)
    fd.append('phone',customer.phone)
    fd.append('email',customer.email)
    fd.append('password',customer.password)
    fd.append('country',customer.country)
    fd.append('city',customer.city)
    fd.append('address',customer.address)

    if(customer.image)    
        fd.append('image',customer.image[0])
       


    return fetch(`${APIURL}/customers/newuser`,{
        method:'POST',
        headers:{
            Accept: 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'content-type': 'multipart/form-data',
            //'Content-Type':'application/json',
            'Authorization':`Bearer ${isAuthenticated().token}`
        },
        body:fd//JSON.stringify(category)
    })
    .then((response)=>{
        console.log(response)
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
//Orders
export const updateOrder = (orderId,order_status)=>{
    return fetch(`${APIURL}/orders/${orderId}`,{
        method:'POST',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(order_status)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const ordersCount = ()=>{
    return fetch(`${APIURL}/orders`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

export const orderList = (skip,limit)=>{
    return fetch(`${APIURL}/orders/list/?skip=${skip}&limit=${limit}`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}

//Shippings
export const shippingCompany = ()=>{
    return fetch(`${APIURL}/shipping`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
//Add Shipping Company
export const AddCompany = (company)=>{
    return fetch(`${APIURL}/shipping2`,{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(company)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const EditCompany = (company)=>{
    return fetch(`${APIURL}/shipping/${company._id}`,{
        method:'PUT',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(company)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
//delete company
export const deleteCompany = (company)=>{
    return fetch(`${APIURL}/shipping/${company._id}`,{
        method:'DELETE',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(company)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
//Payment Methods
export const AddPaymentMethod = (paymentmethods)=>{
    return fetch(`${APIURL}/paymentmethods`,{
        method:'POST',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(paymentmethods)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const StcPaymentMethod = (payment)=>{
    const fd = new FormData();
    
    fd.append('payment_type',payment.payment_type)
    fd.append('payment_activation',payment.payment_activation)
    if(payment.store_code)
        fd.append('store_code',payment.store_code[0])

    return fetch(`${APIURL}/paymentmethods/stc`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const EditPaymentMethod = (paymentmethods)=>{
    return fetch(`${APIURL}/paymentmethods/${paymentmethods._id}`,{
        method:'PUT',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(paymentmethods)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const RemovePaymentMethod = (methodId)=>{
    return fetch(`${APIURL}/paymentmethods/${methodId}`,{
        method:'DELETE',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(company)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const ListPaymentMethod = ()=>{
    return fetch(`${APIURL}/paymentmethods`,{
        method:'GET',
        headers:{
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(company)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const BasicConfigrations = ()=>{
    return fetch(`${APIURL}/configrations`,{
        method:'GET',
        headers:{
            // apikey:APIKEY,
            Accept: 'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        //console.log(response.status,JSON.stringify(response.body))
        return response.json()
    })
    //.then((jsonResponse)=>{
        //console.log(jsonResponse) // here we can catch the response
    //})
    .catch((error)=>{
        //console.log(error)
    })
}
export const BasicConfigrationsUpdate = (configrations)=>{
    const fd = new FormData();
    

    if(configrations.shop_logo)    
        fd.append('shop_logo',configrations.shop_logo[0])

    fd.append('shop_title',configrations.shop_title)
    fd.append('shop_discription',configrations.shop_discription)
    fd.append('facebook_link',configrations.facebook_link)
    fd.append('twiter_link',configrations.twiter_link)
    fd.append('instgram_link',configrations.instgram_link)
    fd.append('youtube_link',configrations.youtube_link)
    fd.append('snapchat_link',configrations.snapchat_link)
    fd.append('contact_info',configrations.contact_info)
    fd.append('shop_email',configrations.shop_email)
    fd.append('shop_phone',configrations.shop_phone)   
    fd.append('marrof_logo',configrations.marrof_logo)
    fd.append('curruncy_symbole',configrations.curruncy_symbole)
    fd.append('return_policy',configrations.return_policy)
    
    return fetch(`${APIURL}/configrations`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const BasicConfigrationsDeleteImage = (configrations)=>{
    const fd = new FormData();
    
    return fetch(`${APIURL}/configrations`,{
        method:'DELETE',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}

export const GetDesignBlocks = (blocks)=>{
    return fetch(`${APIURL}/designconfigrations`,{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
//Click To Add Block
export const AddBlock = (block)=>{
    return fetch(`${APIURL}/designconfigrations`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(block)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const EditBlockArrange = (block,blockId)=>{
    return fetch(`${APIURL}/designconfigrations/${blockId}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify({"arrange":block})
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
export const DeleteBlock = (blockId)=>{
    return fetch(`${APIURL}/designconfigrations/${blockId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify({arrange:block})
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
//Add Image Block 
export const addImageBlock = (block)=>{
    const fd = new FormData();
    

    if(block.block_image)    
        fd.append('block_image',block.block_image[0])   

    fd.append('content',block.link)
    fd.append('arrange',block.arrange)
    fd.append('type','ImageBlock')    

    return fetch(`${APIURL}/designConfigrations/image/add`,{
        method:'POST',
        headers:{
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:fd
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{
        //console.log(error)
    })
}
export const editImageBlock = (block)=>{

}

export const pagesList = ()=>{
    return fetch(`${APIURL}/pages`,{
        method:'GET',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(user)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}

export const AddPage = (page)=>{
    return fetch(`${APIURL}/page`,{
        method:'POST',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(page)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
export const EditPage = (page)=>{
    return fetch(`${APIURL}/page/${page.page_id}`,{
        method:'PUT',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        body:JSON.stringify(page)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}
export const DeletePage = (pageId)=>{
    return fetch(`${APIURL}/page/${pageId}`,{
        method:'DELETE',
        headers:{
            Accept:'application/json',
            'Content-Type':'application/json',
            Authorization:`Bearer ${isAuthenticated().token}`
        },
        //body:JSON.stringify(page)
    })
    .then((response)=>{
        return response.json()
    })
    .catch((error)=>{

    })
}



//Pages