import React,{ useState,useEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import {
    Table,
    Pagination,
    Modal,
    Button,
    Form,
    Tab,
    Tabs,
    Alert
} from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {getSliders,addSlider,editSlider,deleteSlider} from '../API/ApiCore'
import {UPLOADURL} from '../API/ApiConfig'
import ImageUploader from "react-images-upload"


export const Sliders = ({props})=>{

    const [sliders,setSliders]           = useState([]) 

   

    const loadAllSliders = ()=>{
        getSliders().then((data)=>{
            setSliders(data)
        })
    }
    
    useEffect(()=>{
        loadAllSliders()
    },[])
    
    //Add Brand
    const [lgShow,setlgShow] = useState(false)
    const [validated, setValidated] = useState(false);
    const [pictures, setPictures] = useState();

    const onDrop = picture => {
        console.log(picture[0])
        setPictures(picture);
    };
    const addNewSlider = ()=>{
        setlgShow(true)
    }
    const [sliderLink,setSliderLink] = useState('')
    const sliderLinkChange = (e)=>{
        setSliderLink(e.target.value)
    }
    const handleSubmit = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            addSlider({slider_link:sliderLink,slider_image:pictures}).then((data)=>{
                loadAllSliders()
                setlgShow(false)
                setPictures()
                setSliderLink('')
            })
        }

    }
    const [selectedSlider,setSelectedSlider] = useState({})
    const addSelectedSlider = (slider,type)=>{
        setSelectedSlider(slider)
        
        if(type === 'edit'){
            setlgEditShow(true)
            setSliderLink(slider.slider_link)
        }
        if(type === 'delete'){
            setlgShowDelete(true)
        }
    }
    // //Edit 
    const [lgEditShow,setlgEditShow] = useState(false)
    const handleSubmit2 = (event)=>{
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            setValidated(true);
            event.stopPropagation();
        }
        else{
            editSlider({_id:selectedSlider._id,slider_link:sliderLink,slider_image:pictures}).then((data)=>{
                
                setPictures()
                setSliderLink('')
                setSelectedSlider({})
                setlgEditShow(false)
                loadAllSliders()
            })
        }

    }
    // //Delete
    const [lgShowDelete,setlgShowDelete] = useState(false)
    const removeSlider = ()=>{
        deleteSlider(selectedSlider._id).then((data)=>{
            setlgShowDelete(false)
            setSelectedSlider({})
            loadAllSliders()
        })
    }

    return (
        <Card>
          <CardHeader title="الصور المتحركة">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=>addNewSlider()}
              >
                <i class="fas fa-plus"></i>  اضافة صورة 
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <div class="small_note mb-2"><i class="far fa-lightbulb"></i> البانرات الاعلانية المتحركة Slider</div>
            <Table responsive bordered>
                <thead>
                    <tr>
                        <th> الصورة</th>
                        <th>الرابط </th>
                        <th> تاريخ الاضافة</th>
                        <th>خيارات</th>
                    </tr>
                </thead>
                <tbody>
                {sliders.length > 0 && 
                    sliders.map((slider,index)=>{
                        return (
                            <tr key={"TR-"+index}>
                                
                                <td>
                                    {slider.slider_image !== "NaN" && <img width={60}  src={UPLOADURL + "/" + slider.slider_image} className="rounded"/>}
                                    {slider.slider_image === "NaN" && <img width={60}  src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_16bc0bfc086%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_16bc0bfc086%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.9296875%22%20y%3D%2294.5609375%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" className="rounded" />}
                                </td>
                                <td>
                                    {slider.slider_link}
                                </td>
                                <td>
                                    {slider.createdAt}
                                </td>
                                <td>
                                <a
                                    title="Edit Slider"
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                    onClick={() => addSelectedSlider(slider,'edit')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                    />
                                    </span>
                                </a>
                                <a
                                    title="Delete Slider"
                                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                                    onClick={() => addSelectedSlider(slider,'delete')}
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                    </span>
                                </a>
                                </td>
                            </tr>
                        )
                    })
                    
                }
                {sliders.length === 0 && 
                    <tr>
                        <td colSpan={5}> لا توجد بيانات مضافة </td>
                    </tr>
                }
                </tbody>
            </Table>
            
          </CardBody>
            {/* Modal */}
            {/* Add Modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={()=>{setlgShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الرابط</Form.Label>
                            <Form.Control value={sliderLink} required onChange={(e)=>sliderLinkChange(e)} type="text" placeholder="ادخل الرابط" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>الصورة</Form.Label>
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            اضف
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Edit Model */}
            <Modal
                size="lg"
                show={lgEditShow}
                onHide={()=>{setlgEditShow(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    اضافة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit2}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>الرابط</Form.Label>
                            <Form.Control value={sliderLink} required onChange={(e)=>sliderLinkChange(e)} type="text" placeholder="ادخل الرابط" />
                            <Form.Text className="text-muted">
                                حقل اجباري
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>الصورة</Form.Label>
                            <ImageUploader
                            {...props}
                            withIcon={true}
                            onChange={onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                            maxFileSize={5242880}
                            singleImage={true}
                                buttonText={"اختر صورة لرفعها"}
                                label = {" اقصي حجم للصورة 5 ميجا "}
                                fileSizeError = {" حجم الصورة يتعدي المسموح بة "}
                                fileTypeError = {" غير مسموح بهذا النوع من الملفات "}
                                />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            تعديل
                        </Button>
                        
                        <button type="button" onClick = {()=>{setlgEditShow(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Delete Model */}
            <Modal
                size="md"
                show={lgShowDelete}
                onHide={()=>{setlgShowDelete(false)}}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                    حذف
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>هل انت متاكد من الحذف</span>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick = {()=>{setlgShowDelete(false)}} className="btn btn-secondary ml-3">اغلاق</button>
                    <button type="button" onClick = {()=>removeSlider()} className="btn btn-danger">حذف </button>
                </Modal.Footer>
            </Modal>
        </Card>
    
        
      );
}