import React from "react";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";


export function ScrollTop() {
  return (
      <div id="kt_scrolltop" className="scrolltop">
        <i className="fas fa-chevron-up"></i>
      </div>
  );
}
