/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo,useEffect,Fragment} from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHistory } from "react-router-dom";
import {isAuthenticated} from '../../../../../app/API/MarchantAuth';

const {user} = isAuthenticated()//useSelector(state => state.auth);
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserDetailsDropdown() {
  
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.details.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const history = useHistory();
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
};
  useEffect(()=>{
    if(user === undefined) 
    {
      window.location.reload();
    }
  },[user]) 
  return (
    <>
    {
      user !== undefined &&
      <Fragment>
        {layoutProps.offcanvas && (
          <div className="topbar-item">
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_details_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
              </span>
              <span className="pulse-ring"></span>
            </div>
          </div>
        )}
        {!layoutProps.offcanvas && (
          <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
              as={DropdownTopbarItemToggler}
              id="kt_quick_details_toggle"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="user-detail-tooltip">
                    التاجر
                  </Tooltip>
                }
              >
                <div
                  className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                  id="kt_quick_details_toggle"
                >
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}
                    />
                  </span>
                  <span className="pulse-ring"></span>
                  <span className="pulse-ring" />
                </div>
              </OverlayTrigger>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-md">
              <form>
                {/** Head */}
                <div
                  className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  <h5 className="d-flex flex-center rounded-top">
                    <span className="text-white">{user.name}</span>
                    
                  </h5>

                  <Tab.Container defaultActiveKey={key}>
                
                    <Tab.Content className="tab-content">
                      <Tab.Pane eventKey="Alerts" className="p-3">
                        <PerfectScrollbar
                          options={perfectScrollbarOptions}
                          className="scroll pr-7 mr-n7"
                          style={{ maxHeight: "300px", position: "relative" }}
                        >
                          <div className="d-flex align-items-center mb-2">
                      
                            <div className="d-flex flex-column user_menu">
                          
                              <a
                                href="/Profile"
                                className="text-dark text-hover-primary mb-3 font-size-lg"
                              >
                                <i class="far fa-user-circle mr-2"></i> الملف الشخصي
                              </a>

                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-3 font-size-lg"
                              >
                                <i class="far fa-bell mr-2"></i> التنبيهات
                              </a>


                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-3 font-size-lg"
                              >
                                <i class="far fa-grin-hearts mr-2"></i> تحديثات يكسب
                              </a>
                            
                            </div>
                            
                          </div>
                          <div className="user_menu_f mb-3 font-size-lg p-3">
                          <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>خـــروج</button>
                            </div>
                  
                    
                        </PerfectScrollbar>
                      </Tab.Pane>
              
                
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </form>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Fragment>
    }
      
    </>
  );
}
