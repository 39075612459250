export const authenticate = (data,next)=>{
    if(typeof window !== 'undefined'){
        localStorage.setItem('merchant',JSON.stringify(data))
        next()
    }
}
export const isAuthenticated = ()=>{
    if(typeof window == 'undefined'){
        return false
    }

    if(localStorage.getItem('merchant')){
        return JSON.parse(localStorage.getItem('merchant'))
    }
    else{
        return false;
    }
}

export const signout = (next)=>{
    if(typeof window !== 'undefined'){
        localStorage.removeItem('merchant')
        next()
        // return fetch(`${API}/signout`,{
        //     method:'GET'
        // })
        // .then((response)=>{
        //     console.log(response)
        // })
        // .catch((error)=>{
        //     console.log(error)
        // })
    }
}